import ImageSource from '../../types/ImageSource'
import Picture from '../general/Picture'

import Statement from '../general/Statement'
import styled from 'styled-components'
import OurValues from '../general/our-values/OurValues'
import device from '../../styles/devices'

import teamImg from '../../assets/about/about-1.jpg'
import aboutBannerMid from '../../assets/about/about-2.jpg'

const TopSection = styled.section`
  position: relative;

  @media ${device.tablet} {
    padding-left: 48px;
    padding-right: 48px;

    display: flex;
    align-items: flex-start;
  }
`

const PictureWrapper = styled.div`
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 10%;
    width: 50%;
    z-index: 50;
  }

  &::before {
    top: -10%;
    left: 0;
    background: #000;
  }

  &::after {
    bottom: -10%;
    right: 0;
    background: var(--color-primary);
  }

  @media ${device.tablet} {
    flex: 0 0 40%;

    &::before {
      display: none;
    }

    &::after {
      bottom: -14%;
      left: -50%;
      height: 40%;
      width: 100%;
      z-index: 5;
    }
  }

  img {
    position: relative;
    z-index: 20;
  }
`

const StyledStatement = styled(Statement)`
  text-align: center;
  position: relative;
  z-index: 10;

  h1 {
    text-transform: uppercase;
  }

  @media ${device.tablet} {
    position: relative;
    text-align: left;

    left: -2rem;
    top: 2rem;

    h1 {
      font-size: 35px;
    }
  }
`

const BottomSection = styled.section`
  margin-top: -60px;
`

const Heading = styled.h1`
  position: relative;
  background-color: #000;
  color: var(--color-primary);
  padding: 24px;
  text-align: center;
  font-size: 35px;

  @media ${device.tablet} {
    height: 3rem;
    width: 40%;
    top: -4rem;
    z-index: 10;
  }
`

const StyledOurValues = styled(OurValues)`
  @media ${device.laptop} {
    margin-top: -4rem;
  }
`

type Props = {
  className?: string
}

function WhyUs({ className }: Props) {
  const teamImageSources: ImageSource[] = [
    { src: teamImg, device: 'mobile', default: true },
    { src: teamImg, device: 'tablet', default: false },
    { src: teamImg, device: 'laptop', default: false },
  ]

  const bannerImageSources: ImageSource[] = [
    { src: aboutBannerMid, device: 'mobile', default: true },
    { src: aboutBannerMid, device: 'tablet', default: false },
    { src: aboutBannerMid, device: 'laptop', default: false },
    { src: aboutBannerMid, device: 'laptopLarge', default: false },
    { src: aboutBannerMid, device: 'desktop', default: false },
  ]

  return (
    <>
      <TopSection className={className}>
        <PictureWrapper className="md-mb-0">
          <Picture sources={teamImageSources} alt="Our Team" />
        </PictureWrapper>

        <StyledStatement className="bg-gray pt-15 pb-5 md-pt-10 md-pb-10">
          <h1 className="mb-5">Why Choose Us?</h1>
          <p>
            Quality products used in detailing studios. Ease of use for ocassional and every day
            use. Safety for you and your car. All of above at affordable price!
          </p>
        </StyledStatement>
      </TopSection>

      <BottomSection className="relative">
        <Picture sources={bannerImageSources} alt="" imageClass="banner-image" />
        <Heading>CarKleans is...</Heading>
        <StyledOurValues className="pt-10 pb-10 lg-pt-20 lg-pb-20" />
      </BottomSection>
    </>
  )
}

export default WhyUs
