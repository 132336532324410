import { Loader, LoaderOptions } from 'google-maps'
import { FaSearch } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import MoreInfo from '../../components/about/MoreInfo'
import Footer from '../../components/general/Footer'
import PageBanner from '../../components/general/page-banner/PageBanner'
import ProductRecommendations from '../../components/product/ProductRecommendations'
import { RootState } from '../../store'
import device from '../../styles/devices'
import ImageSource from '../../types/ImageSource'

import customPin from '../../assets/custom-pin.png'
import carkleansLogo from '../../assets/carkleans-logo.svg'

import edgeDetailingLogo from '../../assets/edge-detailing-logo.png'
import raproCareLogo from '../../assets/rapro-logo.avif'

import bannerMobile from '../../assets/where-to-buy/banners/banner-mobile.jpg'
import bannerLaptop from '../../assets/where-to-buy/banners/banner-laptop.jpg'
import bannerDesktop from '../../assets/where-to-buy/banners/banner-desktop.jpg'
import { MAPS_API_KEY } from '../../config'

const OffsetWrapper = styled.section`
  position: relative;
  z-index: 0;
`

const EircodeWrapper = styled.div`
  position: relative;

  background: #fff;

  padding-top: 24px;
  padding-bottom: 24px;

  z-index: 10;

  h1 {
    text-align: center;
  }

  @media ${device.tablet} {
    position: absolute;
    top: 5%;
    right: 5%;

    padding: 24px;

    max-width: 50%;

    h1 {
      text-align: left;
    }
  }
`

const InputWrapper = styled.div`
  --item-height: 45px;

  display: flex;

  padding-top: 12px;
  padding-bottom: 12px;

  width: 100%;

  input {
    flex: 1 0 auto;
    box-sizing: border-box;
    height: var(--item-height);
    border: none;
    background-color: #e4e4e4;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  button {
    flex: 0 0 var(--item-height);
    aspect-ratio: 1/1;
    border: none;
    border-radius: 0;
    background-color: #000;
    color: var(--color-primary);
    font-size: 20px;

    cursor: pointer;
    transition: font-size 150ms ease;

    &:hover {
      font-size: 25px;
    }
  }
`

const MapWrapper = styled.div`
  height: 100vw;
  width: 100%;

  @media ${device.tablet} {
    height: 80vw;
  }

  @media ${device.laptopLarge} {
    height: 50vw;
  }

  @media ${device.desktop} {
    height: 800px;
  }
`

const options: LoaderOptions = {}
const loader = new Loader(MAPS_API_KEY, options)

function Merchants() {
  const bannerImages: ImageSource[] = [
    { src: bannerMobile, device: 'mobile', default: true },
    { src: bannerDesktop, device: 'tablet', default: false },
    { src: bannerLaptop, device: 'laptop', default: false },
  ]

  async function init() {
    const google = await loader.load()

    const root = document.querySelector('#map')

const pins = [
      {
        title: 'Edge Detailing',
        coordinates: { lat: 51.87586420510113, lng: -8.481810117053591 },
        content: `
        <div style="background:black;padding-top:20px;padding-bottom:20px;">
        <img src="${edgeDetailingLogo}" style="margin: 0 auto;max-width: 80%;" />
        <div style="padding: 20px;color:#fff;">
          <h1 style="margin-bottom: 20px;">EDGEDETAILING</h1>
          <p>Unit 2, John Harrington Industrial Estate</p>
          <p>Tramore Rd</p>
          <p>Ballyphehane</p>
          <p>Cork, T12 W5RW</p>
          <a style="display:block;margin-top:20px;" href="https://shop.edgedetailing.ie">https://shop.edgedetailing.ie</a>
          <a style="display:block;margin-top:20px;" href="tel:+353851104507">(+353) 85 110 4507</a>
        </p>
        </div>
      </div>`
      },
      {
        title: 'Rapro Care',
        coordinates: { lat: 51.900680439417975, lng: -8.47599501498544 },
        content: `
        <div style="background:black;padding-top:20px;padding-bottom:20px;">
        <img src="${raproCareLogo}" style="margin: 0 auto;max-width: 80%;" />
        <div style="padding: 20px;color:#fff;">
          <h1 style="margin-bottom: 20px;">RAPRO CARE</h1>
          <p>39 Lavitts Quay</p>
          <p>Cork City, T12 P621</p>
          <a style="display:block;margin-top:20px;" href="https://rapro.ie/">https://rapro.ie/</a>
          <a style="display:block;margin-top:20px;" href="tel:+353214814371">(021) 481 4371</a>
        </p>
        </div>
      </div>`
      }
    ]

    if (root) {
      const map = new google.maps.Map(root, {
        center: { lat: 51.890626, lng: -8.478329 },
        zoom: 13,
        disableDefaultUI: true,
      })

      for (const pin of pins) {

        const infoWindow = new google.maps.InfoWindow({ content: pin.content })

        const marker = new google.maps.Marker({
          position: pin.coordinates,
          map,
          title: pin.title,
          icon: customPin,
        })

        marker.addListener('click', () => {
          const iw = infoWindow as any
          iw.open({
            anchor: marker,
            map,
            shouldFocus: false,
          })
        })
      }
    }
  }

  init()

  const recommendations = useSelector((state: RootState) => state.products)

  return (
    <main>
      <PageBanner
        sources={bannerImages}
        alt="Merchants Page Banner"
        heading="FIND A MERCHANT NEAR YOU"
      />

      <OffsetWrapper>
        {/* <EircodeWrapper className="container">
          <h1>Enter your eircode to find a merchant near you</h1>
          <InputWrapper>
            <input type="text" name="eircode" />
            <button>
              <FaSearch />
            </button>
          </InputWrapper>
        </EircodeWrapper> */}
        <MapWrapper id="map" />
      </OffsetWrapper>

      <ProductRecommendations
        products={recommendations}
        title="Check out our products"
        color="alt"
      />

      <MoreInfo />

      <Footer />
    </main>
  )
}

export default Merchants
