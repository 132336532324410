import { useRef } from 'react'
import ImageTile from './ImageTile'

import wheelsTiresImg from '../../../assets/homepage/categories/wheels-tires.png'
import exteriorImg from '../../../assets/homepage/categories/exterior.png'
import interiorImg from '../../../assets/homepage/categories/interior.png'
import accessoriesImg from '../../../assets/homepage/categories/accessories.png'

type Props = {
  className: string
}

function ImageTiles({ className }: Props) {
  const sectionRef = useRef<HTMLElement>(null)

  function onLoad() {
    const observer = new ResizeObserver((entries) => {
      const stretcher = document.querySelector('#background-stretcher') as HTMLElement
      const section = sectionRef.current
      if (!stretcher || !section) return

      const rect = section.getBoundingClientRect()
      const top = section.offsetTop
      const height = rect.height

      const stretcherHeight = top + height * 0.15

      stretcher.style.height = `${stretcherHeight}px`
    })

    const main = document.querySelector('main')
    if (main) observer.observe(main)
  }

  return (
    <section ref={sectionRef} className={`${className} homepage--tiles container`}>
      <ImageTile
        src={wheelsTiresImg}
        to="/categories/wheels-tires"
        alt="Wheels and Tires Products"
        text="Wheel &amp; Tires"
        onload={onLoad}
      />
      <ImageTile
        src={exteriorImg}
        to="/categories/exterior"
        alt="Exterior Products"
        text="Exterior"
      />
      <ImageTile
        src={interiorImg}
        to="/categories/interior"
        alt="Interior Products"
        text="Interior"
      />
      <ImageTile
        src={accessoriesImg}
        to="/categories/accessories"
        alt="Accessory Products"
        text="Accessories"
      />
    </section>
  )
}

export default ImageTiles
