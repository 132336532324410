import ImageSource from '../../types/ImageSource'

import Category from './Category'

import imageStatementSource from '../../assets/categories/exterior/products.png'

import bannerMobile from '../../assets/categories/exterior/banner/banner-mobile.png'
import bannerLaptop from '../../assets/categories/exterior/banner/banner-laptop.png'
import bannerDesktop from '../../assets/categories/exterior/banner/banner-desktop.png'

function Exterior() {
  const categoryAlias = 'exterior'

  const bannerImages: ImageSource[] = [
    { src: bannerMobile, device: 'mobile', default: true },
    { src: bannerDesktop, device: 'tablet', default: false },
    { src: bannerLaptop, device: 'laptop', default: false },
  ]
  const bannerAlt = 'Exterior Cleaning Banner'
  const bannerHeading = 'Exterior'
  const topStatementHeading = 'Shine Like a Star!'
  const topStatementText = 'Browse our range of Exterior cleaning products below!'

  const imageStatementAlt = 'Exterior Cleaning'

  return (
    <Category
      categoryAlias={categoryAlias}
      bannerImages={bannerImages}
      bannerAlt={bannerAlt}
      bannerHeading={bannerHeading}
      topStatementHeading={topStatementHeading}
      topStatementText={topStatementText}
      imageStatementSource={imageStatementSource}
      imageStatementAlt={imageStatementAlt}
      ctaText="Check out our interior cleaning products!"
      ctaButtonText="View products"
      ctaHref="/categories/interior"
    />
  )
}

export default Exterior
