import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import ButtonPrimary from './ButtonPrimary'

import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa'
import device from '../../styles/devices'

const FooterWrapper = styled.footer`
  background-color: #000;
  color: #fff;
  padding-top: 48px;
  padding-bottom: 48px;

  @media ${device.tablet} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media ${device.desktop} {
    max-width: none;
  }
`
const NewsletterWrapper = styled.div`
  flex: 0 1 auto;

  h1 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
  }

  input {
    box-sizing: border-box;
    border-radius: 2px;
    border: none;
    width: 100%;
    height: 40px;

    @media ${device.desktop} {
      height: 52px;
    }
  }

  button {
    width: 100%;

    @media ${device.tablet} {
      width: 40%;
    }

    @media ${device.desktop} {
      height: 52px;
    }
  }

  @media ${device.tablet} {
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const LeftFlex = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: space-between;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    justify-content: flex-start;
  }

  a {
    transition: color 250ms ease;

    & + a {
      margin-top: 16px;

      @media ${device.tablet} {
        margin-top: 12px;
      }
    }

    &:hover {
      color: var(--color-primary);
    }
  }
`

const LinksLeft = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    padding-right: 3vw;
    border-right: 2px solid var(--color-primary);
  }
`

const LinksRight = styled.div`
  display: flex;
  flex-direction: column;

  text-align: right;

  @media ${device.tablet} {
    text-align: left;

    padding-left: 3vw;
  }
`

const SocialsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    transition: all 150ms ease;

    &:hover {
      transform: translateY(-0.25em);
      box-shadow: 0 0.5em 0.5em -0.4em var(--color-primary);

      svg {
        border-radius: 8px;
      }
    }

    svg {
      color: #000;
      padding: 8px;
      font-size: 20px;
      background-color: var(--color-primary);
      display: block;
      transition: border-radius 250ms ease;
    }
  }

  @media ${device.tablet} {
    justify-content: flex-start;
    column-gap: 1rem;
  }
`

type Props = {
  className?: string
}

function Footer({ className }: Props) {
  function scrollToTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <FooterWrapper className={`${className} container`}>
      <NewsletterWrapper>
        <h1>Sign up to our Newsletter</h1>
        <p>Sign up to our newsletter to receive exlusive email offers and promotions</p>
        <input type="email" disabled></input>
        <ButtonPrimary className="mt-2 uppercase" disabled={true}>
          Coming Soon!
        </ButtonPrimary>
      </NewsletterWrapper>

      <LeftFlex>
        <LinksWrapper className="mt-12 mb-12 md-mt-0 md-mb-8">
          <LinksLeft>
            <NavLink onClick={scrollToTop} to="/about">
              About
            </NavLink>
            <NavLink onClick={scrollToTop} to="/merchants">
              Where to Buy
            </NavLink>
            <NavLink onClick={scrollToTop} to="/downloadables">
              Downloadables
            </NavLink>
            <NavLink onClick={scrollToTop} to="/contact">
              Contact us
            </NavLink>
          </LinksLeft>
          <LinksRight>
            <NavLink onClick={scrollToTop} to="/categories/wheels-tires">
              Wheels &amp; Tires
            </NavLink>
            <NavLink onClick={scrollToTop} to="/categories/exterior">
              Exterior
            </NavLink>
            <NavLink onClick={scrollToTop} to="/categories/interior">
              Interior
            </NavLink>
            {/* <NavLink onClick={scrollToTop} to="/categories/accessories">Accessories</NavLink> */}
          </LinksRight>
        </LinksWrapper>
        <SocialsWrapper className="">
          <a href="https://www.facebook.com/CarKleans-102249795888131">
            <FaFacebookF />
          </a>
          <a href="https://www.instagram.com/carkleans/">
            <FaInstagram />
          </a>
          {/* <a href="https://www.google.com">
            <FaTiktok />
          </a> */}
        </SocialsWrapper>
      </LeftFlex>
    </FooterWrapper>
  )
}

export default Footer
