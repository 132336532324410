export function waitForElement(querySelector: string, timeout: number): Promise<Element> {
  return new Promise((resolve, reject) => {
    var timer: number = -1

    let el = document.querySelector(querySelector)
    if (el) return resolve(el)

    const observer = new MutationObserver(() => {
      let el = document.querySelector(querySelector)
      if (el) {
        observer.disconnect()
        if (timer !== -1) clearTimeout(timer)
        return resolve(el)
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    if (timeout)
      timer = window.setTimeout(() => {
        observer.disconnect()
        reject()
      }, timeout)
  })
}

export function randomString() {
  return (Math.random() + 1).toString(36).substring(7)
}
