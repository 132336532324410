import styled from 'styled-components'
import device from '../../styles/devices'
import ImageSource from '../../types/ImageSource'

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`

type Props = {
  sources: ImageSource[]
  alt: string
  imageClass?: string
}

function Picture({ sources, alt, imageClass }: Props) {
  function createSources() {
    const src = []

    for (const source of sources) {
      const key = `key-${(Math.random() + 1).toString(36).substring(7)}`

      if (source.device != null)
        src.push(<source srcSet={source.src} media={device[source.device]} key={key} />)
      else if (source.breakpoint != null) {
        const query = `(min-width: ${source.breakpoint.min}) and (max-width: ${source.breakpoint.max})`
        src.push(<source srcSet={source.src} media={query} key={key} />)
      }
    }

    return src
  }

  function createDefaultSource() {
    let defaultSource = sources.find((s) => s.default)

    if (!defaultSource) {
      defaultSource = sources[0]
    }

    return defaultSource
  }

  return (
    <picture>
      {createSources()}
      <Image className={imageClass} src={createDefaultSource().src} alt={alt} />
    </picture>
  )
}

export default Picture
