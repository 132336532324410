import { createSlice } from '@reduxjs/toolkit'

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: [
    { id: 1, name: 'Wheels & Tires', alias: 'wheels-tires' },
    { id: 2, name: 'Exterior', alias: 'exterior' },
    { id: 3, name: 'Interior', alias: 'interior' },
    { id: 4, name: 'Accessories', alias: 'accessories' },
  ],
  reducers: {},
})

export default categoriesSlice.reducer
