import styled from 'styled-components'

import mailIcon from '../../assets/mail-icon.png'
import device from '../../styles/devices'

const Wrapper = styled.div`
  h1 {
    text-transform: uppercase;
    font-size: 28px;

    span {
      display: block;
    }
  }

  a {
    font-size: 28px;
    font-weight: bold;
  }

  @media ${device.tablet} {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    h1 {
      font-size: 35px;
    }
  }

  @media ${device.laptop} {
    h1 {
      font-size: 45px;
    }
  }
`

const Address = styled.a`
  display: block;
  position: relative;

  &::before {
    background-image: url(${mailIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    content: '';
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0;
    left: 0;

    transition: transform 250ms ease-in-out;
    transition-delay: 250ms;
  }

  &:hover {
    &::before {
      transform: translate(15px, -15px);
    }

    span::after {
      width: 100%;
    }
  }

  @media ${device.tablet} {
    text-align: left;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::before {
      top: -25px;
      right: -25px;
      left: auto;
    }

    h2 {
      font-size: 25px;
      font-weight: 300;
    }
  }

  @media ${device.laptop} {
    align-items: flex-start;

    &::before {
      top: -45px;
      right: -45px;
    }

    h2 {
      font-size: 35px;
    }
  }
`

const MailLink = styled.span`
  position: relative;

  &::after {
    content: '';
    height: 4px;
    width: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--color-primary);
    transition: width 250ms ease-in-out;
  }
`

function MoreInfo() {
  return (
    <section className="bg-gray pt-10 pb-10 lg-pt-20 lg-pb-20">
      <Wrapper className="container">
        <h1>
          Want to<span>know more?</span>
        </h1>
        <Address href="mailto:carkleans@gmail.com" className="mt-10 md-mt-0 text-right">
          <h2>Drop us an e-mail at</h2>
          <MailLink>carkleans@gmail.com</MailLink>
        </Address>
      </Wrapper>
    </section>
  )
}

export default MoreInfo
