import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import NavDropdown from './NavDropdown'
import NavItem from './NavItem'

import device from '../../../styles/devices'
import logo from '../../../logo.png'

import './nav-mobile.scss'
import './nav-default.scss'

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;

  opacity: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  margin-top: 48px;
  pointer-events: none;

  transition: opacity 125ms ease-in-out;

  &.shown {
    opacity: 0.9;
    pointer-events: all;
  }

  @media ${device.laptop} {
    display: none;
  }
`

function Nav() {
  const [isShown, setIsShown] = useState(false)
  const [isMenuButtonDisabled, setIsMenuButtonDisabled] = useState(false)

  function hide() {
    setIsShown(false)
  }

  useEffect(() => {
    const liElements = document.querySelector('[data-id="nav-menu"]')?.querySelectorAll('li')
    if (!liElements) return

    liElements[liElements.length - 1].addEventListener(
      'animationend',
      () => {
        setIsMenuButtonDisabled(false)
      },
      {
        once: true,
      }
    )

    liElements?.forEach((li) => {
      if (isShown) {
        li.classList.remove('out')
        li.classList.add('in')
      } else {
        li.classList.remove('in')
        li.classList.add('out')
      }
    })

    if (isShown) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  }, [isShown])

  function toggle() {
    setIsMenuButtonDisabled(true)
    setIsShown(!isShown)
  }

  return (
    <nav id="nav-main" className="container">
      <Overlay className={isShown ? 'shown' : ''} />

      <NavLink to="/" onClick={hide}>
        <img id="nav-main--logo-img" src={logo} alt="carkleans logo" />
      </NavLink>

      <menu id="nav-main--menu" data-id="nav-menu">
        <NavItem to="/about" text="About" onClick={hide} />
        <NavDropdown title="Products" hideFn={hide} />
        <NavItem to="/merchants" text="Where to Buy" onClick={hide} />
        <NavItem to="/downloadables" text="Downloadables" onClick={hide} />
        <NavItem to="/contact" text="Contact" onClick={hide} />
      </menu>

      <button
        id="nav-main--button-toggle"
        onClick={toggle}
        disabled={isMenuButtonDisabled}
        role="menu"
      >
        <span className={`material-icons ${!isShown ? 'shown' : ''}`}>menu</span>
        <span className={`material-icons ${isShown ? 'shown' : ''}`}>close</span>
      </button>
    </nav>
  )
}

export default Nav
