import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../store'

import PageBanner from '../../components/general/page-banner/PageBanner'
import ProductInfo from '../../components/product/ProductInfo'
import ProductRecommendations from '../../components/product/ProductRecommendations'
import Footer from '../../components/general/Footer'

function Product() {
  const { id } = useParams()

  // TODO
  if (!id) throw new Error(`ID NOT PROVIDED - IMPLEMENT ERROR PAGE`)

  const product = useSelector((state: RootState) =>
    state.products.find((p) => p.id === Number.parseInt(id))
  )

  if (!product) throw new Error(`PRODUCT ${id} NOT FOUND`)

  const category = useSelector((state: RootState) =>
    state.categories.find((c) => c.id === product.categoryId)
  )

  if (!category) throw new Error(`CATEGORY ${product.categoryId} NOT FOUND`)

  const recommended = useSelector((state: RootState) =>
    state.products.filter((p) => p.categoryId === category.id && p.id !== product.id)
  )

  return (
    <main>
      <PageBanner
        sources={product.bannerImages}
        alt={`${product.name} Banner`}
        heading={product.name.toUpperCase()}
        text={product.shortDescription}
      />

      <ProductInfo category={category} product={product} />

      <ProductRecommendations products={recommended} />

      <Footer />
    </main>
  )
}

export default Product
