import './homepage.scss'

import styled from 'styled-components'

import homepageBanner_square from '../../assets/homepage/banner/homepage_1600_1600.jpg'
import homepageBanner_768_375 from '../../assets/homepage/banner/homepage_768_375.jpg'
import homepageBanner_1024_384 from '../../assets/homepage/banner/homepage_1024_384.jpg'
import homepageBannerXL from '../../assets/homepage/banner/homepage_2400_1600.jpg'

import homepageDesktop from '../../assets/homepage/banner/homepage-desktop.jpg'

import bestsellerImg from '../../assets/homepage/bestseller.png'

import BestSeller from '../../components/homepage/best-seller/BestSeller'
import HomepageMap from '../../components/homepage/homepage-map/HomepageMap'
import PageBanner from '../../components/general/page-banner/PageBanner'
import ImageTiles from '../../components/homepage/image-tiles/ImageTiles'
import Footer from '../../components/general/Footer'
import HomepageMission from '../../components/homepage/homepage-mission/HomepageMission'
import OurValues from '../../components/general/our-values/OurValues'
import ImageSource from '../../types/ImageSource'

const BackgroundStretcher = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100px;

  z-index: 0;

  transition: height 100ms ease-in-out;
`

function Homepage() {
  const bannerImages: ImageSource[] = [
    { src: homepageBanner_square, device: 'mobile', default: true },
    { src: homepageBanner_768_375, device: 'tablet', default: false },
    { src: homepageBanner_1024_384, device: 'laptop', default: false },
    { src: homepageBannerXL, device: 'laptopLarge', default: false },
    { src: homepageDesktop, device: 'desktop', default: false },
  ]

  return (
    <main className="bg-gray">
      <BackgroundStretcher id="background-stretcher"></BackgroundStretcher>
      <PageBanner
        sources={bannerImages}
        alt="Homepage Banner"
        heading="CarKleans"
        text="At CarKleans we believe that professional products help achieve professional finish. Our quality products are not only easy to use but also safe for you and your car."
      />
      <HomepageMission />
      <ImageTiles className="mt-10 mb-10" />
      <BestSeller src={bestsellerImg} alt="" className="mt-10 mb-10 md-mt-24" />
      <HomepageMap className="mt-10 md-mt-0 md-mb-0" />
      <OurValues className="pt-10 pb-10 md-pt-20 md-pb-20" />
      <Footer className="" />
    </main>
  )
}

export default Homepage
