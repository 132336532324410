import ImageSource from '../../types/ImageSource'

import imageStatementSource from '../../assets/categories/interior/products.png'

import Category from './Category'

import bannerMobile from '../../assets/categories/interior/banner/banner-mobile.jpg'
import bannerLaptop from '../../assets/categories/interior/banner/banner-laptop.jpg'
import bannerDesktop from '../../assets/categories/interior/banner/banner-desktop.jpg'

function Interior() {
  const categoryAlias = 'interior'

  const bannerImages: ImageSource[] = [
    { src: bannerMobile, device: 'mobile', default: true },
    { src: bannerDesktop, device: 'tablet', default: false },
    { src: bannerLaptop, device: 'laptop', default: false },
  ]

  const bannerAlt = 'Interior Cleaning Banner'
  const bannerHeading = 'Interior'
  const topStatementHeading = 'Shine Like a Star!'
  const topStatementText = 'Browse our range of Interior cleaning products below!'

  const imageStatementAlt = 'Interior Cleaning'

  return (
    <Category
      categoryAlias={categoryAlias}
      bannerImages={bannerImages}
      bannerAlt={bannerAlt}
      bannerHeading={bannerHeading}
      topStatementHeading={topStatementHeading}
      topStatementText={topStatementText}
      imageStatementSource={imageStatementSource}
      imageStatementAlt={imageStatementAlt}
      ctaText="Check out our exterior cleaning products!"
      ctaButtonText="View products"
      ctaHref="/categories/exterior"
    />
  )
}

export default Interior
