import styled from 'styled-components'

import phoneIcon from '../../assets/phone-icon.png'

import device from '../../styles/devices'

const Section = styled.section`
  background-color: var(--color-primary);
`

const Wrapper = styled.div`
  h1 {
    text-transform: uppercase;
    font-size: 28px;

    span {
      display: block;
    }
  }

  a {
    font-size: 28px;
    font-weight: bold;
  }

  @media ${device.tablet} {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    h1 {
      font-size: 35px;
    }
  }

  @media ${device.laptop} {
    h1 {
      font-size: 45px;
    }
  }
`

const Phone = styled.a`
  display: block;
  position: relative;

  &::before {
    background-image: url(${phoneIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    content: '';
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    &::before {
      animation-name: shake;
      animation-duration: 250ms;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
    }

    span::after {
      width: 100%;
    }
  }

  @keyframes shake {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(-5deg);
    }
    50% {
      transform: rotate(0);
    }
    75% {
      transform: rotate(5deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  @media ${device.tablet} {
    text-align: left;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::before {
      top: -25px;
      right: -25px;
      left: auto;
    }

    h2 {
      font-size: 25px;
      font-weight: 300;
    }
  }

  @media ${device.laptop} {
    align-items: flex-start;

    &::before {
      top: -45px;
      right: -45px;
    }

    h2 {
      font-size: 35px;
    }
  }
`

const PhoneLink = styled.span`
  position: relative;

  &::after {
    content: '';
    height: 4px;
    width: 0;
    position: absolute;
    bottom: -2px;
    right: 0;
    background: #000;
    transition: width 250ms ease-in-out;
  }
`

function CallUs() {
  return (
    <Section className="pt-10 pb-10 lg-pt-20 lg-pb-20">
      <Wrapper className="container">
        <h1>
          Give Us<span>a call</span>
        </h1>
        <Phone href="tel:+353818882488" className="mt-10 md-mt-0 text-right">
          <h2>Call us on</h2>
          <PhoneLink>(+353) 818 882 488</PhoneLink>
        </Phone>
      </Wrapper>
    </Section>
  )
}

export default CallUs
