import styled from 'styled-components'
import Nav from './nav/Nav'

const Head = styled.header`
  background-color: #000;
  z-index: 1;
  position: relative;
`

function Header() {
  return (
    <Head>
      <Nav />
    </Head>
  )
}

export default Header
