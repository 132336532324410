import { configureStore } from '@reduxjs/toolkit'

import categoriesReducer from './slices/categories'
import productsReducer from './slices/products'

const store = configureStore({
  reducer: {
    products: productsReducer,
    categories: categoriesReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
