import styled from 'styled-components'
import device from '../../styles/devices'
import { DownloadableFile } from '../../types/DownloadableFile'
import DownloadItem from './DownloadItem'

const FILES: DownloadableFile[] = [
  // {
  //   name: 'V2 CarKleans A5 Leaftlet.pdf',
  //   type: 'pdf',
  //   previewSrc: 'V2 CarKleans A5 Leaftlet.png',
  // },
  {
    "name": "Before _ After(1).jpg",
    "type": "jpg",
    "previewSrc": "Before _ After(1).jpg"
  },
  {
    "name": "Before _ After.jpg",
    "type": "jpg",
    "previewSrc": "Before _ After.jpg"
  },
  {
    "name": "Car Interior.jpg",
    "type": "jpg",
    "previewSrc": "Car Interior.jpg"
  },
  {
    "name": "Car Kleans.jpg",
    "type": "jpg",
    "previewSrc": "Car Kleans.jpg"
  },
  {
    "name": "Connect.jpg",
    "type": "jpg",
    "previewSrc": "Connect.jpg"
  },
  {
    "name": "EDGE-112.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-112.jpg"
  },
  {
    "name": "EDGE-122.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-122.jpg"
  },
  {
    "name": "EDGE-165.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-165.jpg"
  },
  {
    "name": "EDGE-237.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-237.jpg"
  },
  {
    "name": "EDGE-26.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-26.jpg"
  },
  {
    "name": "EDGE-29.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-29.jpg"
  },
  {
    "name": "EDGE-364.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-364.jpg"
  },
  {
    "name": "EDGE-366.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-366.jpg"
  },
  {
    "name": "EDGE-368.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-368.jpg"
  },
  {
    "name": "EDGE-370.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-370.jpg"
  },
  {
    "name": "EDGE-377.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-377.jpg"
  },
  {
    "name": "EDGE-383.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-383.jpg"
  },
  {
    "name": "EDGE-388.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-388.jpg"
  },
  {
    "name": "EDGE-391.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-391.jpg"
  },
  {
    "name": "EDGE-393.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-393.jpg"
  },
  {
    "name": "EDGE-394.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-394.jpg"
  },
  {
    "name": "EDGE-397.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-397.jpg"
  },
  {
    "name": "EDGE-398.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-398.jpg"
  },
  {
    "name": "EDGE-399.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-399.jpg"
  },
  {
    "name": "EDGE-400.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-400.jpg"
  },
  {
    "name": "EDGE-404.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-404.jpg"
  },
  {
    "name": "EDGE-405.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-405.jpg"
  },
  {
    "name": "EDGE-409.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-409.jpg"
  },
  {
    "name": "EDGE-43 Wheel Cleaner.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-43 Wheel Cleaner.jpg"
  },
  {
    "name": "EDGE-437.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-437.jpg"
  },
  {
    "name": "EDGE-448.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-448.jpg"
  },
  {
    "name": "EDGE-47.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-47.jpg"
  },
  {
    "name": "EDGE-483.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-483.jpg"
  },
  {
    "name": "EDGE-485.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-485.jpg"
  },
  {
    "name": "EDGE-500.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-500.jpg"
  },
  {
    "name": "EDGE-508.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-508.jpg"
  },
  {
    "name": "EDGE-511.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-511.jpg"
  },
  {
    "name": "EDGE-517.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-517.jpg"
  },
  {
    "name": "EDGE-531.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-531.jpg"
  },
  {
    "name": "EDGE-543.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-543.jpg"
  },
  {
    "name": "EDGE-549.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-549.jpg"
  },
  {
    "name": "EDGE-552.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-552.jpg"
  },
  {
    "name": "EDGE-57.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-57.jpg"
  },
  {
    "name": "EDGE-578.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-578.jpg"
  },
  {
    "name": "EDGE-580.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-580.jpg"
  },
  {
    "name": "EDGE-591.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-591.jpg"
  },
  {
    "name": "EDGE-609.jpg",
    "type": "jpg",
    "previewSrc": "EDGE-609.jpg"
  },
  {
    "name": "Glass Cleaner.jpg",
    "type": "jpg",
    "previewSrc": "Glass Cleaner.jpg"
  },
  {
    "name": "Interior ✅(1).jpg",
    "type": "jpg",
    "previewSrc": "Interior ✅(1).jpg"
  },
  {
    "name": "Interior ✅.jpg",
    "type": "jpg",
    "previewSrc": "Interior ✅.jpg"
  },
  {
    "name": "MSC.jpg",
    "type": "jpg",
    "previewSrc": "MSC.jpg"
  },
  {
    "name": "One Step Closer.jpg",
    "type": "jpg",
    "previewSrc": "One Step Closer.jpg"
  },
  {
    "name": "PH7 Gel.jpg",
    "type": "jpg",
    "previewSrc": "PH7 Gel.jpg"
  },
  {
    "name": "Product Quality Post.jpg",
    "type": "jpg",
    "previewSrc": "Product Quality Post.jpg"
  },
  {
    "name": "Saturday Vibes.jpg",
    "type": "jpg",
    "previewSrc": "Saturday Vibes.jpg"
  },
  {
    "name": "The Aftermath.jpg",
    "type": "jpg",
    "previewSrc": "The Aftermath.jpg"
  },
  {
    "name": "Tuesday(1).jpg",
    "type": "jpg",
    "previewSrc": "Tuesday(1).jpg"
  },
]

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`

function DownloadsList() {
  function renderItems() {
    return FILES.map((file) => {
      return <DownloadItem file={file} key={file.name} />
    })
  }

  return (
    <section className="mt-10 mb-10">
      <div className="container bg-gray pt-10 pb-10">
        <List>{renderItems()}</List>
      </div>
    </section>
  )
}

export default DownloadsList
