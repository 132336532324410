import { FaCaretLeft } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

type Props = {
  text: string
  to: string
  onClick: () => void
}

function NavDropdownItem({ text, to, onClick }: Props) {
  return (
    <li>
      <NavLink
        to={to}
        onClick={(evt) => {
          evt.stopPropagation()
          onClick()
        }}
      >
        <span>{text}</span>
        <FaCaretLeft />
      </NavLink>
    </li>
  )
}

export default NavDropdownItem
