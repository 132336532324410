import DownloadsList from '../../components/downloadables/DownloadsList'
import Footer from '../../components/general/Footer'
import PageBanner from '../../components/general/page-banner/PageBanner'
import Statement from '../../components/general/Statement'

import ImageSource from '../../types/ImageSource'

import bannerMobile from '../../assets/downloadables/banners/banner-mobile.jpg'
import bannerLaptop from '../../assets/downloadables/banners/banner-laptop.jpg'
import bannerDesktop from '../../assets/downloadables/banners/banner-desktop.jpg'

function Downloadables() {
  const bannerImages: ImageSource[] = [
    { src: bannerMobile, device: 'mobile', default: true },
    { src: bannerDesktop, device: 'tablet', default: false },
    { src: bannerLaptop, device: 'laptop', default: false },
  ]

  return (
    <main>
      <PageBanner
        sources={bannerImages}
        alt="Homepage Banner"
        heading="DOWNLOADABLES"
        text="Amazing content available to download"
      />

      <DownloadsList />

      <Footer />
    </main>
  )
}

export default Downloadables
