import { DownloadableFile } from '../../types/DownloadableFile'

import styled from 'styled-components'

const Wrapper = styled.li`
  --spacing: 12px;

  --border-style: solid;
  --border-width: 2px;
  --border-color: #e4e4e4;

  --border: var(--border-width) var(--border-style) var(--border-color);

  position: relative;

  border: var(--border);
`

const TopWrap = styled.div`
  background-color: #fff;

  h1 {
    padding: var(--spacing);
    text-align: center;
  }
`

const ImageWrapper = styled.a`
  img {
    width: auto;
    max-width: 100%;
    height: 300px;
    object-fit: contain;
    margin: 0 auto;
  }
`

const DownloadLink = styled.a`
  display: block;
  background-color: var(--color-primary);
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;

  padding-top: 6px;
  padding-bottom: 6px;

  border-radius: 2px;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &:hover {
    transform: translateY(-0.25em);
    box-shadow: 0 0.5em 0.5em -0.4em var(--color-primary);
  }
`

type Props = {
  file: DownloadableFile
}

function DownloadItem({ file }: Props) {
  const PREVIEW_URL = `${process.env.PUBLIC_URL}/downloadables/${file.previewSrc}`
  const DOWNLOAD_URL = `${process.env.PUBLIC_URL}/downloadables/${file.name}`

  return (
    <Wrapper>
      <TopWrap>
        <ImageWrapper href={DOWNLOAD_URL} target="_blank" rel="noreferrer">
          <img src={PREVIEW_URL} alt={`${file.name} file preview`} />
        </ImageWrapper>
        {/* <h1>{file.name}</h1> */}
      </TopWrap>
      <DownloadLink href={DOWNLOAD_URL} download>
        DOWNLOAD
      </DownloadLink>
    </Wrapper>
  )
}

export default DownloadItem
