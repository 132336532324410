import styled from 'styled-components'
import { Category } from '../../types/Category'
import { Product } from '../../types/Product'

import { IoIosArrowForward } from 'react-icons/io'

import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import ProductTile from './ProductTile'
import device, { getCurrentDevice } from '../../styles/devices'
import { useState } from 'react'

import '@splidejs/react-splide/css'

const Wrapper = styled.section`
  padding-bottom: 24px;
  position: relative;

  @media ${device.tablet} {
    margin-top: 90px;
    padding-bottom: 90px;
  }

  &.alt {
    margin-top: 0;
    margin-bottom: 0;

    background-color: #fff;

    h1 {
      background-color: var(--color-primary);
      color: #000;

      @media ${device.tablet} {
        width: 67%;
      }
    }
  }
`

const StyledH1 = styled.h1`
  box-sizing: border-box;
  background: #000;
  color: var(--color-primary);
  text-align: center;
  position: relative;

  padding-top: 24px;
  padding-bottom: 24px;

  margin-bottom: 24px;

  font-size: 30px;

  @media ${device.tablet} {
    width: 50%;
    height: 90px;
    top: -45px;
  }

  @media ${device.desktop} {
    height: 106px;
    font-size: 40px;
    top: -53px;
  }
`

type Props = {
  products: Product[]
  title?: string
  color?: 'default' | 'alt'
}

function ProductRecommendations({ products, title, color }: Props) {
  const titleProp = title || 'You may also like...'
  const colorProp = color || 'default'

  const device = getCurrentDevice()
  const categories = useSelector((state: RootState) => state.categories)

  const [splideOptions, setSplideOptions] = useState({
    rewind: true,
    rewindByDrag: true,
    perPage: device === 'mobile' ? 2 : 4,
    gap: '14px',
    pagination: false,
  })

  function useRenderSlides() {
    return products.map((p) => {
      const category = categories.find((c) => c.id === p.categoryId)
      if (!category) throw new Error('CATEGORY NOT FOUND')

      return (
        <SplideSlide key={`product_${p.id}`}>
          <ProductTile category={category} product={p} />
        </SplideSlide>
      )
    })
  }

  return (
    <Wrapper className={`${colorProp} bg-gray`}>
      <StyledH1>{titleProp}</StyledH1>
      <Splide className="container" hasTrack={false} options={splideOptions}>
        <SplideTrack>{useRenderSlides()}</SplideTrack>

        <div className="splide__arrows">
          <button className="splide__arrow splide__arrow--prev">
            <IoIosArrowForward />
          </button>
          <button className="splide__arrow splide__arrow--next">
            <IoIosArrowForward />
          </button>
        </div>
      </Splide>
    </Wrapper>
  )
}

export default ProductRecommendations
