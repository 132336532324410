export default class Dialog {
  private dialog: HTMLDivElement

  constructor() {
    this.dialog = this.createDialog()
  }

  private createOverlay(): HTMLDivElement {
    const overlay = document.createElement('div')
    overlay.style.cssText = `
        box-sizing: border-box;
        position: fixed;
        top: 0;
        left: 0;

        width: 100vw;
        height: 100vh;

        padding: 24px;

        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    `

    return overlay
  }

  private createContentBox(): HTMLDivElement {
    const modal = document.createElement('div')
    modal.style.cssText = `
        box-sizing: border-box;
        width: 100%;
        min-height: 100px;
        max-width: 800px;
        background-color: #fff;
        border-radius: 2px;
        border: 4px solid var(--color-primary);
    `

    return modal
  }

  private createHeading(): HTMLHeadingElement {
    const heading = document.createElement('h1')

    heading.style.cssText = `
    text-align: center;
    padding: 24px 0;
    font-size: 35px;
    `

    return heading
  }

  private createParagraph() {
    const paragraph = document.createElement('p')

    paragraph.style.cssText = `
        text-align: center;
        padding: 24px 0;
        font-size: 25px;
    `

    return paragraph
  }

  private createCloseButton(): HTMLDivElement {
    const btnBox = document.createElement('div')
    const btn = document.createElement('button')
    btn.innerHTML = 'OK'
    btn.addEventListener('click', () => {
      this.hide()
    })

    btnBox.style.cssText = `
        padding: 12px;
    `

    btn.style.cssText = `
        box-sizing: border-box;
        border: none;
        background-color: var(--color-primary);
        color: #000;
        height: 36px;
        font-size: 18px;
        font-weight: 550;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 2px;
        cursor: pointer;
        transition: all 150ms ease-in-out;

        width: 100%;
    
        :hover {
            transform: translateY(-0.25em);
            box-shadow: 0 0.5em 0.5em -0.4em var(--color-primary);
        }

        @media screen and (min-width: 500px) {
          background-color: red;
        }
    `

    btnBox.appendChild(btn)

    return btnBox
  }

  private createDialog(): HTMLDivElement {
    const overlay = this.createOverlay()
    const contentBox = this.createContentBox()
    const heading = this.createHeading()
    const paragraph = this.createParagraph()
    const button = this.createCloseButton()

    overlay.appendChild(contentBox)
    contentBox.appendChild(heading)
    contentBox.appendChild(paragraph)
    contentBox.appendChild(button)

    return overlay
  }

  public hide() {
    const body = document.body
    body.removeChild(this.dialog)
    body.style.overflow = 'visible'
  }

  public show(heading: string, text: string = '') {
    const body = document.body

    body.style.overflow = 'hidden'

    const h1 = this.dialog.querySelector('h1')
    if (h1) h1.innerHTML = heading

    const paragraph = this.dialog.querySelector('p')
    if (paragraph) paragraph.innerHTML = text

    body.appendChild(this.dialog)
  }
}
