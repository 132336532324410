import styled from 'styled-components'
import device from '../../../styles/devices'
import ImageSource from '../../../types/ImageSource'
import MessageBox from '../message-box/MessageBox'
import Picture from '../Picture'

const Wrapper = styled.section`
  position: relative;
  z-index: 0;

  height: 100vw;
  width: 100%;

  @media ${device.tablet} {
    height: auto;
  }
`

const MessageBoxWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
  }
`

const StyledMessageBox = styled(MessageBox)`
  position: absolute;
  bottom: 5%;
  left: 5%;
  display: flex;
  align-items: flex-start;

  h1 {
    margin: 0;
    padding: 12px 0;
  }

  @media ${device.tablet} {
    position: relative;
    max-width: 40%;
    bottom: 0;
  }
`

type Props = {
  sources: ImageSource[]
  alt: string
  heading: string
  text?: string
}

function HomepageBodyHeader({ sources, alt, heading, text }: Props) {
  return (
    <Wrapper>
      <Picture imageClass="banner-image" sources={sources} alt={alt} />

      <MessageBoxWrapper className="message-box-wrapper">
        <StyledMessageBox>
          <h1>{heading}</h1>
          {text && <p>{text}</p>}
        </StyledMessageBox>
      </MessageBoxWrapper>
    </Wrapper>
  )
}

export default HomepageBodyHeader
