import { useEffect, useRef, useState } from 'react'
import { FaCaretDown, FaCaretLeft } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import NavDropdownItem from './NavDropdownItem'

type Props = {
  title: string
  hideFn: () => void
}

function NavDropdown({ hideFn, title }: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLUListElement>(null)

  const dropdownTitleRef = useRef<HTMLSpanElement>(null)

  const [dropdownTitle, setDropdownTitle] = useState(title)

  function useHasActiveChild(callback: (hasHactive: boolean) => void) {
    const location = useLocation()

    useEffect(() => {
      const ul = dropdownRef.current

      if (!ul) return callback(false)

      const activeElement = ul.querySelector('.active')
      if (activeElement) return callback(true)
      else return callback(false)
    }, [location, callback])
  }

  function getElements() {
    const wrapper = wrapperRef.current
    const dropdown = dropdownRef.current

    if (!wrapper || !dropdown) throw new Error('MISSIN REQUIRED DROPDOWN ELEMENTS')

    return { wrapper, dropdown }
  }

  function checkIsTransitioning(wrapper: HTMLDivElement): boolean {
    const isTransitioning = wrapper.getAttribute('data-transitioning')

    if (isTransitioning) return true

    wrapper.setAttribute('data-transitioning', 'true')

    wrapper.addEventListener(
      'transitionend',
      () => {
        wrapper.removeAttribute('data-transitioning')
      },
      { once: true }
    )

    return false
  }

  function showDropdown() {
    const { wrapper, dropdown } = getElements()
    if (checkIsTransitioning(wrapper)) return
    const dropdownHeight = dropdown.offsetHeight
    wrapper.style.height = `${dropdownHeight}px`
    wrapper.setAttribute('data-open', 'true')
  }

  function hideDropdown() {
    const { wrapper } = getElements()
    if (getComputedStyle(wrapper).height === '0px') return
    if (checkIsTransitioning(wrapper)) return

    wrapper.style.height = '0'
    wrapper.setAttribute('data-open', 'false')
  }

  function toggleDropdown() {
    if (!isMobile()) return

    const { wrapper } = getElements()
    const isOpen = wrapper.getAttribute('data-open') === 'true'
    isOpen ? hideDropdown() : showDropdown()
  }

  function isMobile() {
    const toggleButton = document.querySelector('#nav-main--button-toggle') as HTMLButtonElement

    if (!toggleButton) throw new Error('TOGGLE BUTTON MISSING')

    const style = getComputedStyle(toggleButton)
    return style.display !== 'none'
  }

  function closeAll() {
    if (!isMobile()) {
      hideDropdown()
    }

    hideFn()
  }

  function renderItems() {
    const keyRandom = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substring(0, 5)
    const items = [
      { title: 'Wheels & Tires', to: '/categories/wheels-tires' },
      { title: 'Exterior', to: '/categories/exterior' },
      { title: 'Interior', to: '/categories/interior' },
      // { title: 'Accessories', to: '/categories/accessories' },
    ]
    const elems = []

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      elems.push(
        <NavDropdownItem
          text={item.title}
          to={item.to}
          onClick={() => {
            if (isMobile()) closeAll()
          }}
          key={`${keyRandom}-item-${i}`}
        ></NavDropdownItem>
      )
    }

    return elems
  }

  useHasActiveChild((hasActive: boolean) => {
    const titleContainer = dropdownTitleRef.current
    if (!titleContainer) return

    if (hasActive) titleContainer.classList.add('has-active')
    else titleContainer.classList.remove('has-active')
  })

  return (
    <li onClick={toggleDropdown} className="relative menu--dropdown">
      <div className="menu--dropdown-header">
        <FaCaretDown className="nav-main--menu--caret" />
        <span ref={dropdownTitleRef}>{dropdownTitle}</span>
      </div>
      <div ref={wrapperRef} className="menu--dropdown-wrapper">
        <ul ref={dropdownRef} className="menu--dropdown-inner">
          {renderItems()}
        </ul>
      </div>
    </li>
  )
}

export default NavDropdown
