import styled from 'styled-components'
import { Category } from '../../types/Category'
import { Product } from '../../types/Product'

import device, { DEVICE_WIDTH } from '../../styles/devices'
import SplideGallery from './SplideGallery'

import { useLayoutEffect, useState } from 'react'

import CarouselHorizontal from '../general/carousel/CarouselHorizontal'
import CarouselVertical from '../general/carousel/CarouselVertical'

const Wrapper = styled.section`
  padding-bottom: 28px;
  overflow: hidden;

  @media ${device.tablet} {
    display: flex;

    overflow: visible;
  }
`

const FlexLeft = styled.div`
  @media ${device.tablet} {
    flex: 0 0 67%;
  }
`
const FlexRight = styled.div`
  position: relative;

  @media ${device.tablet} {
    flex: 0 1 33%;

    display: flex;
    flex-direction: column;

    justify-content: flex-start;
  }
`

const MainProductImageWrapper = styled.div`
  display: none;
  @media ${device.tablet} {
    position: relative;
    display: block;
    width: 100%;
    aspect-ratio: 1/1;

    margin-top: -50%;
    margin-bottom: 50%;
  }
`

const MainProductImage = styled.img`
  @media ${device.tablet} {
    width: 100%;
    aspect-ratio: 1/1;
  }
`

const StyledArticle = styled.article`
  --article-padding: 28px;

  padding-top: var(--article-padding);
  padding-bottom: var(--article-padding);

  & + article {
    border-top: 2px solid #fff;
  }
`

const ProductName = styled.h1`
  padding-bottom: 12px;
  text-transform: uppercase;

  text-align: center;

  @media ${device.tablet} {
    font-size: 30px;
    text-align: left;
  }
`

const ShortDescription = styled.i`
  font-style: italic;

  display: block;
  padding-bottom: 12px;

  text-align: center;

  @media ${device.tablet} {
    font-size: 20px;
    text-align: left;
  }
`

const LongDescriptionWrapper = styled.div`
  display: flex;

  @media ${device.tablet} {
    align-items: flex-start;
  }
`

const LongDescription = styled.p`
  font-weight: bold;
  text-align: center;

  @media ${device.tablet} {
    text-align: left;
    font-size: 16px;
  }
`

const MobileImageWrapper = styled.div`
  flex: 0 1 auto;
  position: relative;

  img {
    height: auto;
    width: 100%;
  }

  @media ${device.tablet} {
    display: none;
  }
`

const ProductProperties = styled.ul`
  display: flex;
  justify-content: space-around;

  padding-top: 24px;

  li {
    padding: 10px;
    flex: 1 0 50%;
    text-align: center;

    & + li {
      border-left: 1px solid #000;
    }
  }

  @media ${device.tablet} {
    justify-content: flex-start;

    li {
      flex: 0 0 auto;
      padding-right: 1rem;

      & + li {
        padding-left: 1rem;
      }
    }
  }
`

const MobileBlock = styled.span`
  display: block;

  @media ${device.tablet} {
    display: inline;
  }
`

const StyledH2 = styled.h2`
  font-size: 18px;
  padding-bottom: var(--article-padding);
`

const UsageList = styled.ol`
  li {
    display: flex;

    & + li {
      margin-top: 12px;
    }

    span {
      flex: 0 0 10%;
      display: block;
      text-align: center;
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }
  }
`

const WarningList = styled.ul`
  li + li {
    margin-top: 12px;
  }
`

type Props = {
  category: Category
  product: Product
}

function ProductInfo({ category, product }: Props) {
  function productImageSrc() {
    return require(`../../assets/categories/${category.alias}/products/${product.image}`)
  }

  function renderUsageList() {
    if (!product.usage) return ''

    const els = []

    for (let i = 0; i < product.usage.length; i++) {
      els.push(
        <li key={`usage-${i}`}>
          <span>{i + 1}.</span>
          <p>{product.usage[i]}</p>
        </li>
      )
    }

    return els
  }

  function renderGalleryImages() {
    return product.gallery.map((i) => {
      return (
        <div className="carousel-slide" key={`carousel-slide-${i}`}>
          <img
            src={require(`../../assets/products/gallery/${i}`)}
            alt={product.name + ' gallery'}
          />
        </div>
      )
    })
  }

  const [orientation, setOrientation] = useState<'horizontal' | 'vertical'>('horizontal')

  function checkOrientation() {
    if (window.innerWidth < DEVICE_WIDTH.tablet) setOrientation('horizontal')
    else setOrientation('vertical')
  }

  window.addEventListener('resize', () => {
    checkOrientation()
  })

  function renderCarousel() {
    if (orientation === 'horizontal')
      return <CarouselHorizontal>{renderGalleryImages()}</CarouselHorizontal>
    else return <CarouselVertical>{renderGalleryImages()}</CarouselVertical>
  }

  useLayoutEffect(() => {
    checkOrientation()
  })

  return (
    <Wrapper className="container bg-gray md-mt-10 lg-mt-20">
      <FlexLeft>
        <div className="md-pl-5 md-pr-5">
          <StyledArticle>
            <MobileImageWrapper>
              <img src={productImageSrc()} alt={product.name} />
            </MobileImageWrapper>
            <ProductName>{product.name}</ProductName>
            <ShortDescription>{product.shortDescription}</ShortDescription>
            <LongDescriptionWrapper>
              <LongDescription>{product.longDescription}</LongDescription>
            </LongDescriptionWrapper>

            <ProductProperties>
              {product.ph ? (
                <li>
                  <MobileBlock>pH:&nbsp;</MobileBlock>
                  {product.ph}
                </li>
              ) : (
                ''
              )}
              <li>
                <MobileBlock>Capacities:&nbsp;</MobileBlock>
                {product.capacity}
              </li>
            </ProductProperties>
          </StyledArticle>

          {product.usage ? (
            <StyledArticle>
              <StyledH2>Usage</StyledH2>
              <UsageList>{renderUsageList()}</UsageList>
            </StyledArticle>
          ) : (
            ''
          )}

          <StyledArticle>
            {product.warnings ? <StyledH2>Attention!</StyledH2> : ''}
            {product.warnings ? (
              <WarningList>
                {product.warnings.map((item, index) => {
                  return <li key={`warning-${index}`}>{item}</li>
                })}
              </WarningList>
            ) : (
              ''
            )}
          </StyledArticle>
        </div>
      </FlexLeft>
      <FlexRight>
        <MainProductImageWrapper id="product-image-wrapper">
          <MainProductImage src={productImageSrc()} alt={product.name} />
        </MainProductImageWrapper>
        {/* {renderCarousel()} */}
      </FlexRight>
    </Wrapper>
  )
}

export default ProductInfo
