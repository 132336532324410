import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import device from '../../styles/devices'
import { Category } from '../../types/Category'
import { Product } from '../../types/Product'

const Wrapper = styled(NavLink)`
  display: block;

  h2 {
    background-color: #000;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding: 6px 0;
  }

  @media ${device.desktop} {
    h2 {
      font-size: 18px;
    }
  }
`

const ImageWrapper = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;

  img {
    background-color: transparent;
    width: 100%;
    height: auto;
    transition: transform 150ms ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`

type Props = {
  category: Category
  product: Product
}

function ProductTile({ category, product }: Props) {
  const imagePath = require(`../../assets/categories/${category.alias}/products/${product.image}`)
  const linkTo = `/products/${product.id}`

  function scrollTo(y: number) {
    window.scroll({
      top: y,
      behavior: 'smooth',
    })
  }

  function scrollToTop() {
    const el = document.querySelector('.message-box-wrapper')

    if (!el) {
      scrollTo(0)
      return
    }

    const bodyRect = document.body.getBoundingClientRect()
    const rect = el.getBoundingClientRect()

    if (!rect) {
      scrollTo(0)
      return
    }

    const offset = rect.top - bodyRect.top
    scrollTo(offset)
  }

  return (
    <Wrapper className="bg-gray" to={linkTo} onClick={scrollToTop}>
      <ImageWrapper>
        <img src={imagePath} alt={product.name} />
      </ImageWrapper>
      <h2>{product.name}</h2>
    </Wrapper>
  )
}

export default ProductTile
