import styled from 'styled-components'
import device from '../../../styles/devices'
import { NavLink } from 'react-router-dom'

const TileWrapper = styled(NavLink)`
  display: block;
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  @media (${device.tablet}) {
    & + & {
      margin-top: 0;
    }
  }
`

const TileOverlay = styled.h2`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: center;
  padding: 12px;
  font-size: 1.1rem;

  @media ${device.laptopLarge} {
    font-size: 24px;
  }

  @media ${device.desktop} {
    font-size: 28px;
  }
`

type Props = {
  src: string
  alt: string
  text: string
  to: string
  onload?: (this: GlobalEventHandlers, ev: Event) => any
}

function ImageTile(props: Props) {
  const image = new Image()
  if (props.onload) image.onload = props.onload
  image.src = props.src

  return (
    <TileWrapper to={props.to}>
      <img src={image.src} alt={props.alt} />
      <TileOverlay>{props.text}</TileOverlay>
    </TileWrapper>
  )
}

export default ImageTile
