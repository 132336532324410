import styled from 'styled-components'
import './best-seller.scss'

const Wrapper = styled.section`
  z-index: 1;
`

type Props = {
  className?: string
  src: string
  alt: string
}

export default function BestSeller({ src, alt, className }: Props) {
  return (
    <Wrapper className={`${className ?? ''} bestseller--wrapper`}>
      <h1>
        Our<span> Best Seller</span>
      </h1>
      <div className="bestseller--bottom-wrapper">
        <div className="bestseller--image-wrapper">
          <img src={src} alt={alt} />
        </div>
        <div className="bestseller--description container">
          <h2>Synthetic Wax</h2>
          <p>
            QD+ gives amazing shine to a vehicles paintwork leaving it smooth and clean for many
            weeks. Once applied QD+ repels water and dirt with ease. Has all the properties of hard
            waxes with a much easier application process.
          </p>
        </div>
      </div>
    </Wrapper>
  )
}
