import styled from 'styled-components'
import device from '../../../styles/devices'

import qualityIcon from '../../../assets/homepage/icons/quality.svg'
import easeOfUseIcon from '../../../assets/homepage/icons/ease-of-use.svg'
import safetyIcon from '../../../assets/homepage/icons/safety.svg'
import affordabilityIcon from '../../../assets/homepage/icons/affordability.svg'

const Wrapper = styled.div`
  background: #fff;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 2rem;
  row-gap: 2rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media ${device.desktop} {
  }
`
const ItemWrapper = styled.div`
  h1 {
    text-align: center;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: center;
    font-size: 12px;
  }

  @media ${device.laptop} {
    h1 {
      font-size: 28px;
    }

    p {
      margin-top: 8px;
      font-size: 16px;
    }
  }

  @media ${device.desktop} {
    p {
      max-width: 200px;
    }
  }
`
const IconsWrapper = styled.div`
  color: var(--color-primary);
  text-align: center;

  margin-bottom: 10px;

  & > span {
    font-size: 1.8rem;
  }

  img {
    width: 100%;
    height: 48px;
  }
`

type Props = {
  className?: string
}

function OurValues({ className }: Props) {
  return (
    <section className={`${className ?? ''} bg-white`}>
      <Wrapper className="container">
        {/* Quality */}
        <ItemWrapper>
          <IconsWrapper>
            <img src={qualityIcon} alt="Quality" />
          </IconsWrapper>
          <h1>Professional Quality Products</h1>
          <p>
            We believe every car owner should have access to professional car cleaning products that
            won’t break the bank. Whether you’re looking for a cleaner for your wheels and exterior
            or just want to freshen up the inside with top-notch interior car cleaning products, we
            have something for you. Everything we make is of the highest quality and completely safe
            for your use. With our full lineup of car cleaning products, you can dress for success
            from wheel to wheel.
          </p>
        </ItemWrapper>
        {/* Ease of Use */}
        <ItemWrapper>
          <IconsWrapper>
            <img src={easeOfUseIcon} alt="Ease of Use" />
          </IconsWrapper>
          <h1>User Friendly and Functional</h1>
          <p>
            CarKleans makes products that are both powerful and easy to use. Each of our products
            comes with clear directions that will make you feel like a pro in no time. It doesn’t
            matter if you’re looking for an everyday car cleaning kit or something to use once in a
            while; our products are as straightforward as they come.
          </p>
        </ItemWrapper>
        {/* Safety */}
        <ItemWrapper>
          <IconsWrapper>
            <img src={safetyIcon} alt="Safety" />
          </IconsWrapper>
          <h1>Safe</h1>
          <p>
            We choose safety for users and cars. Our products are safer to use than some other
            brands available on the market.
          </p>
        </ItemWrapper>
        {/* Affordable */}
        <ItemWrapper>
          <IconsWrapper>
            <img src={affordabilityIcon} alt="Affordable" />
          </IconsWrapper>
          <h1>Totally Affordable</h1>
          <p>
            No one should have to break the bank to keep their cars happy. We budget carefully to
            ensure you get the highest-quality car cleaning products at the affordable prices. You
            won’t have to spend a million bucks to look like a million bucks. With CarKleans, you’ll
            have the freedom to shine.
          </p>
        </ItemWrapper>
      </Wrapper>
    </section>
  )
}

export default OurValues
