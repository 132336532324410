import ImageSource from '../../types/ImageSource'

import PageBanner from '../../components/general/page-banner/PageBanner'
import Statement from '../../components/general/Statement'
import styled from 'styled-components'
import ProductTiles from '../../components/product/ProductTiles'

import Footer from '../../components/general/Footer'
import device from '../../styles/devices'
import { FaChevronRight } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import LinkPrimary from '../../components/general/LinkPrimary'

const PageWrapper = styled.main`
  overflow: hidden;
`

const StyledStatement = styled(Statement)`
  background: #fff;
  padding-top: 24px;
  padding-bottom: 24px;

  h2 {
    padding-bottom: 12px;
  }

  @media ${device.desktop} {
    padding-top: 80px;
    padding-bottom: 80px;

    h2 {
      font-size: 45px;
    }

    p {
      font-size: 25px;
    }
  }
`

const StyledStatementImage = styled(StyledStatement)`
  padding-top: 48px;
  padding-bottom: 48px;

  h1 {
    font-size: 20px;
  }

  @media ${device.tablet} {
    padding-bottom: 64px;
  }

  @media ${device.laptop} {
    padding-bottom: 80px;
  }

  @media ${device.laptopLarge} {
    padding-bottom: 100px;
  }

  @media ${device.desktop} {
    padding-bottom: 150px;
  }
`

const FlexLeft = styled.div`
  display: flex;
  flex-direction: column;

  flex: 0 0 70%;

  h1 {
    text-align: center;
  }

  a {
  }

  @media ${device.tablet} {
    flex: 0 0 50%;

    h1 {
      text-align: left;
      font-weight: normal;
      font-size: 26px;
    }
  }
`

const FlexRight = styled.div`
  position: relative;
  flex: 1 0 auto;

  img {
    height: 140px;
    width: 140px;
    position: absolute;
    right: -56px;
  }

  @media ${device.tablet} {
    img {
      height: 300px;
      width: 300px;

      bottom: -48px;
      right: 0;
    }
  }

  @media ${device.laptop} {
    img {
      height: 350px;
      width: 350px;

      bottom: -64px;
    }
  }

  @media ${device.laptopLarge} {
    img {
      right: 125px;
    }
  }

  @media ${device.desktop} {
    img {
      width: 400px;
      height: 400px;

      bottom: -140px;
    }
  }
`

const LinkContainer = styled.div`
  display: flex;
`

const StyledLink = styled(LinkPrimary)`
  font-size: 12px;
  text-align: left;
  font-weight: 300;
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: auto;

  svg {
    display: none;
  }

  @media ${device.tablet} {
    font-size: 16px;

    svg {
      display: block;
      margin-left: 12px;
      font-size: 18px;
    }
  }

  @media ${device.laptopLarge} {
    font-size: 22px;
    padding: 24px;
  }
`

type Props = {
  categoryAlias: string

  bannerImages: ImageSource[]
  bannerAlt: string
  bannerHeading: string

  topStatementHeading: string
  topStatementText: string

  imageStatementSource: string
  imageStatementAlt: string

  ctaText: string
  ctaButtonText: string
  ctaHref: string
}

function Category(props: Props) {
  const category = useSelector((state: RootState) =>
    state.categories.find((c) => c.alias === props.categoryAlias)
  )

  if (!category) throw new Error('CATEGORY NOT FOUND')

  return (
    <PageWrapper>
      <PageBanner
        sources={props.bannerImages}
        alt={props.bannerAlt}
        heading={props.bannerHeading}
      />

      <StyledStatement>
        <h2 style={{ fontSize: '36px', fontWeight: 'bold' }}>{props.topStatementHeading}</h2>
        <p style={{ fontSize: '25px' }}>{props.topStatementText}</p>
      </StyledStatement>

      <ProductTiles category={category} />

      <StyledStatementImage>
        <div className="flex">
          <FlexLeft>
            <h1>{props.ctaText}</h1>
            <LinkContainer>
              <StyledLink href={props.ctaHref}>
                <span>{props.ctaButtonText}</span>
                <FaChevronRight />
              </StyledLink>
            </LinkContainer>
          </FlexLeft>
          <FlexRight>
            <img src={props.imageStatementSource} alt={props.imageStatementAlt} />
          </FlexRight>
        </div>
      </StyledStatementImage>

      <Footer />
    </PageWrapper>
  )
}

export default Category
