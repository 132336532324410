import CallUs from '../../components/contact/CallUs'
import ContactForm from '../../components/contact/ContactForm'
import ContactMap from '../../components/contact/ContactMap'
import Footer from '../../components/general/Footer'
import PageBanner from '../../components/general/page-banner/PageBanner'
import ImageSource from '../../types/ImageSource'

import bannerMobile from '../../assets/contact/banners/banner-mobile.jpg'
import bannerLaptop from '../../assets/contact/banners/banner-laptop.jpg'
import bannerDesktop from '../../assets/contact/banners/banner-desktop.jpg'
import MoreInfo from '../../components/about/MoreInfo'

function Contact() {
  const bannerImages: ImageSource[] = [
    { src: bannerMobile, device: 'mobile', default: true },
    { src: bannerDesktop, device: 'tablet', default: false },
    { src: bannerLaptop, device: 'laptop', default: false },
  ]

  return (
    <main>
      <PageBanner sources={bannerImages} alt="Contact Page Banner" heading="CONTACT US" />

      <ContactForm />

      <CallUs />

      <MoreInfo />

      <ContactMap />

      <Footer />
    </main>
  )
}

export default Contact
