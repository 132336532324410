import ImageSource from '../../types/ImageSource'

import imageStatementSource from '../../assets/categories/wheels-tires/products.png'
import Category from './Category'

import bannerMobile from '../../assets/categories/wheels-tires/banner/banner-mobile.png'
import bannerLaptop from '../../assets/categories/wheels-tires/banner/banner-laptop.png'
import bannerDesktop from '../../assets/categories/wheels-tires/banner/banner-desktop.png'

function WheelsTires() {
  const categoryAlias = 'wheels-tires'

  const bannerImages: ImageSource[] = [
    { src: bannerMobile, device: 'mobile', default: true },
    { src: bannerDesktop, device: 'tablet', default: false },
    { src: bannerLaptop, device: 'laptop', default: false },
  ]

  const bannerAlt = 'Wheels and Tires Banner'
  const bannerHeading = 'Wheels & Tires'
  const topStatementHeading = 'Shine Like a Star!'
  const topStatementText = 'Browse our range of Wheel and Tire cleaning products below'
  const imageStatementAlt = 'Wheel Cleaning'

  return (
    <Category
      categoryAlias={categoryAlias}
      bannerImages={bannerImages}
      bannerAlt={bannerAlt}
      bannerHeading={bannerHeading}
      topStatementHeading={topStatementHeading}
      topStatementText={topStatementText}
      imageStatementSource={imageStatementSource}
      imageStatementAlt={imageStatementAlt}
      ctaText="Check out our exterior cleaning products!"
      ctaButtonText="View products"
      ctaHref="/categories/exterior"
    />
  )
}

export default WheelsTires
