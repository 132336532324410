export interface ValidationResponse {
  valid: boolean
  errorKey: string
}

export function emailValid(email: string): ValidationResponse {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (email == null || email === undefined || email === '') {
    return { valid: false, errorKey: 'validation.enterEmail' }
  } else if (!re.test(email.toLowerCase())) {
    return {
      valid: false,
      errorKey: 'validation.enterValidEmail',
    }
  } else {
    return { valid: true, errorKey: '' }
  }
}

export function isEmpty(value: any) {
  if (value) return false
  else return true
}

export function greaterThan(value: number, bottom: number) {
  return value > bottom
}

export function lessThan(value: number, top: number) {
  return value < top
}
