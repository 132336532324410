import styled from 'styled-components'
import './form-controls.scss'

const Error = styled.p`
  margin-bottom: 12px;

  :empty {
    display: none;
  }
`

type Props = {
  id: string
  label: string
  value: string
  onChange: (value: string) => void
  tabindex?: number
  error?: string
}

function FormTextarea({ id, label, value, onChange, tabindex, error }: Props) {
  return (
    <div className="form-control">
      <label htmlFor={id}>{label}</label>
      <textarea
        id={id}
        value={value}
        tabIndex={tabindex}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      ></textarea>
      <Error className="form-error">{error}</Error>
    </div>
  )
}

export default FormTextarea
