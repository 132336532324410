import { ReactElement } from 'react'

type Props = {
  children: ReactElement | ReactElement[]
  className?: string
}

function Statement({ children, className }: Props) {
  return <section className={`container ${className ?? ''}`}>{children}</section>
}

export default Statement
