import ImageSource from '../../types/ImageSource'

import imageStatementSource from '../../assets/accessories.png'
import Category from './Category'

function Accessories() {
  const categoryAlias = 'accessories'

  const bannerImages: ImageSource[] = [
    { src: 'https://picsum.photos/id/491/768', device: 'mobile', default: true },
    { src: 'https://picsum.photos/id/491/1024/500', device: 'tablet', default: false },
    { src: 'https://picsum.photos/id/491/1280/600', device: 'laptop', default: false },
    { src: 'https://picsum.photos/id/491/1536/600', device: 'laptopLarge', default: false },
    { src: 'https://picsum.photos/id/491/1536/600', device: 'desktop', default: false },
  ]

  const bannerAlt = 'Accessories Cleaning Banner'
  const bannerHeading = 'Accessories'
  const topStatementHeading = 'Shine Like a Star!'
  const topStatementText =
    ' Sed porta ipsum at nunc commodo, nec interdum dui porta. Donec risus diam, congue at nunc ullamcorper, ultricies convallis velit. Curabitur vulputate mattis ligula nec volutpat. In faucibus dapibus ipsum et finibus. Phasellus rutrum tristique dapibus.'

  const imageStatementAlt = 'Accessories Cleaning'

  return (
    <Category
      categoryAlias={categoryAlias}
      bannerImages={bannerImages}
      bannerAlt={bannerAlt}
      bannerHeading={bannerHeading}
      topStatementHeading={topStatementHeading}
      topStatementText={topStatementText}
      imageStatementSource={imageStatementSource}
      imageStatementAlt={imageStatementAlt}
      ctaText="Check out our exterior cleaning products!"
      ctaButtonText="View products"
      ctaHref="/categories/exterior"
    />
  )
}

export default Accessories
