import { createSlice } from '@reduxjs/toolkit'
import { Product } from '../../types/Product'

import wheelsBannerMobile from '../../assets/categories/wheels-tires/banner/banner-mobile.png'
import wheelsBannerLaptop from '../../assets/categories/wheels-tires/banner/banner-laptop.png'
import wheelsBannerDesktop from '../../assets/categories/wheels-tires/banner/banner-desktop.png'

import exteriorBannerMobile from '../../assets/categories/exterior/banner/banner-mobile.png'
import exteriorBannerLaptop from '../../assets/categories/exterior/banner/banner-laptop.png'
import exteriorBannerDesktop from '../../assets/categories/exterior/banner/banner-desktop.png'

import interiorBannerMobile from '../../assets/categories/interior/banner/banner-mobile.jpg'
import interiorBannerLaptop from '../../assets/categories/interior/banner/banner-laptop.jpg'
import interiorBannerDesktop from '../../assets/categories/interior/banner/banner-desktop.jpg'

const initialState: Product[] = [
  // WHEELS & TIRES
  // Bloody Wheel - Liquid
  {
    id: 1,
    categoryId: 1,
    name: 'Bloody Wheel - Liquid',
    shortDescription: 'Neutral Iron Fallout Remover / Wheel Cleaner',
    longDescription:
      'Removes brake dust, volatile rust and other metallic contaminants from rims and paintwork.  Lasts longer on the surface thanks to gel consistency. Safe for paint and all kinds of rims incl. lacquered, chromed, polished, etc.  Neutral pH.',
    ph: '7 - Neutral',
    capacity: '500ml / 1L / 5L',
    usage: [
      'Spray directly onto the surface, covering it completely.',
      'Leave to react (1 - 2 minutes) Bloody Wheel can even work for up to 20 mins on the surface - but make sure NOT to let it dry.',
      'Agitate surface with a brush or a sponge.',
      'Rinse thoroughly under pressure.',
      'Repeat steps 1- 4 if necessary.',
    ],
    warnings: ['Do not apply in direct sunlight or on to hot surfaces.', 'Do not allow to dry.'],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_bloody_wheel_LIQUID_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: wheelsBannerMobile, device: 'mobile', default: true },
      { src: wheelsBannerDesktop, device: 'tablet', default: false },
      { src: wheelsBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Bloody Wheel - Gel
  {
    id: 2,
    categoryId: 1,
    name: 'Bloody Wheel - Gel',
    shortDescription: 'Neutral Iron Fallout Remover / Wheel Cleaner',
    longDescription:
      'Removes brake dust, volatile rust and other metallic contaminants from rims and paintwork.  Lasts longer on the surface thanks to gel consistency. Safe for paint and all kinds of rims incl. lacquered, chromed, polished, etc.  Neutral pH.',
    ph: '7 - Neutral',
    capacity: '500ml / 1L / 5L',
    usage: [
      'Spray directly onto the surface, covering it completely.',
      'Leave to react (1 - 2 minutes) Bloody Wheel can even work for up to 20 mins on the surface - but make sure NOT to let it dry.',
      'Agitate surface with a brush or a sponge.',
      'Rinse thoroughly under pressure.',
      'Repeat steps 1- 4 if necessary.',
    ],
    warnings: ['Do not apply in direct sunlight or on to hot surfaces.', 'Do not allow to dry.'],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_bloody_wheel_GEL_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: wheelsBannerMobile, device: 'mobile', default: true },
      { src: wheelsBannerDesktop, device: 'tablet', default: false },
      { src: wheelsBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Tire and Rubber
  {
    id: 3,
    categoryId: 1,
    name: 'Tire & Wheel Cleaner',
    shortDescription: 'Tire & Wheel Cleaner',
    longDescription:
      'Designed for effective cleaning of tires, rubber elements, rubber mats and aluminum and steel rims. Advanced formula effectively penetrates and separates even the most stubborn dirt, leaving tires and rims thoroughly cleaned.',
    capacity: '500ml / 1L / 5L',
    usage: [
      'Spray onto wet or dry surface and wait for approx 30sec.',
      'Agitate surface with a brush.',
      'Rinse thoroughly under pressure.',
      'Repeat steps 1-2 if necessary.',
    ],
    warnings: ['Do not apply in direct sunlight or on to hot surfaces.', 'Do not allow to dry.'],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_tire_and_rubber_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: wheelsBannerMobile, device: 'mobile', default: true },
      { src: wheelsBannerDesktop, device: 'tablet', default: false },
      { src: wheelsBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Wheel cleaner
  {
    id: 4,
    categoryId: 1,
    name: 'Wheel Cleaner',
    shortDescription: 'Wheel Cleaner for toughest jobs out there!',
    longDescription:
      'Strongly acidic, highly concentrated cleaner for rims with almond scent. Easily removes dirt and rust. It’s designed to work on aluminium and steel rims. Do NOT use on chrome or lacquered parts.',
    capacity: '500ml / 1L / 5L',
    ph: '2',
    usage: [
      'Dilute according to recommendations and your preferences. 1:10 -  1:20 ',
      'Apply the diluted cleaner to the dirty rim.',
      'Agitate surface with a brush or a sponge.',
      'Rinse the rim thoroughly under pressure.',
    ],
    warnings: [
      'Always test diluted solution in an inconspicuous area prior to use.',
      'Do not apply in direct sunlight or on hot surfaces.',
      'Do not allow to dry on cleaned surfaces.',
      'Do not allow contact with paint and glass.',
      'Do not use on chrome rims.',
    ],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_wheel_cleaner_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: wheelsBannerMobile, device: 'mobile', default: true },
      { src: wheelsBannerDesktop, device: 'tablet', default: false },
      { src: wheelsBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // EXTERIOR
  // Bug Remover
  {
    id: 5,
    categoryId: 2,
    name: 'Bug Remover',
    shortDescription: 'Bug Cleaner for the toughest jobs out there!',
    longDescription:
      'Advanced formula designed to remove organic contamination from cars body. Quickly removes insects and bird droppings. Universal product which will work both with everyday care without affecting waxes as well as with strong dirt and full decontamination. At lower concentrations - may require repeated use.',
    capacity: '500ml / 1L / 5L',
    usage: [
      'Dilute according to recommendations and your preferences. 1:1 -  1:4 (Safe for Waxes, may need repeated use)',
      'Spray onto cool surface.',
      'Leave for about 1-2 min - Do not allow to dry.',
      'Rinse thoroughly under pressure.',
    ],
    warnings: [
      'Do not apply in direct sunlight or on hot surfaces.',
      'Do not allow to dry on the cleaned surface.',
    ],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_bug_remover_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: exteriorBannerMobile, device: 'mobile', default: true },
      { src: exteriorBannerDesktop, device: 'tablet', default: false },
      { src: exteriorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Citrus Foam
  {
    id: 6,
    categoryId: 2,
    name: 'Citrus Foam',
    shortDescription: 'Highly concentrated active foam for pre-washing with citrus scent.',
    longDescription:
      'Effectively dissolves dirt and degreases the surface. Safe for paint and ceramic coatings.',
    capacity: '500ml / 1L / 5L',
    usage: [
      'Dilute the foam according to recommendations and your preferences. PA 1:8 - 1:20   |  Hand foamer  1:20 - 1:40',
      'Spread the foam evenly using a foamer and leave it for 3-8 minutes.',
      'Do not allow the foam to dry on the surface.',
      'Rinse thoroughly with high-pressure water',
    ],
    warnings: [
      'Do not apply in direct sunlight or on hot surfaces.',
      'Do not allow to dry on the cleaned surface.',
    ],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_citrus_foam_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: exteriorBannerMobile, device: 'mobile', default: true },
      { src: exteriorBannerDesktop, device: 'tablet', default: false },
      { src: exteriorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Tech Shampoo
  {
    id: 7,
    categoryId: 2,
    name: 'Tech Shampoo',
    shortDescription: 'Acidic Shampoo for vehicle washing.',
    longDescription:
      'Ideal for deep cleaning and unclogging of ceramic and quartz coatings. Harmless to waxes and sealants at low concentrations.',
    capacity: '500ml / 1L / 5L',
    ph: '6',
    usage: [
      'Dilute according to recommendations and your preferences. 1:10 - 1:40 - hand foamers   |   1:50 - 1:200 - hand washing',
      'Use a clean and soft sponge, glove or mitt to wash the car.',
      'If you are using hand foamer, apply foam on the vehicle, wait until the foam reacts with dirt.',
      'Rinse thoroughly under pressure.',
    ],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_tech_shampoo_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: exteriorBannerMobile, device: 'mobile', default: true },
      { src: exteriorBannerDesktop, device: 'tablet', default: false },
      { src: exteriorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Syntetic Wax
  {
    id: 8,
    categoryId: 2,
    name: 'Synthetic Wax [QD+]',
    shortDescription: 'QD+ Synthetic Wax - Quick and Easy to apply  ',
    longDescription:
      'QD+ is a synthetic wax that is super quick and easy to apply. QD+ gives amazing shine to a vehicles paintwork leaving it smooth and clean for many weeks. Once applied QD+ repels water and dirt with ease.  Has all the properties of hard waxes with a much easier application process. Simply spray it onto desired surface and wipe with microfibe cloth. Can be applied onto dry or wet surface. QD+ lasts for  approx 1.5 months depending on weather conditions & cleaning method. It does not clog ceramic coatings or waxes, can be used on top of them.',
    capacity: '500ml / 1L / 5L',
    usage: ['Apply to dry or wet paint', 'Wipe dry with microfibre towel'],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_synthetic_wax_quick_detailer_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: exteriorBannerMobile, device: 'mobile', default: true },
      { src: exteriorBannerDesktop, device: 'tablet', default: false },
      { src: exteriorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Shampoo
  {
    id: 9,
    categoryId: 2,
    name: 'SHAMPOO - Neutral pH',
    shortDescription: 'Neutral pH Shampoo for vehicle washing.',
    longDescription:
      'Highly concentrated, neutral pH shampoo. Ideal for removing dirt from your vehicle, leaving no stains behind. Produces supreme foam. Perfect for daily care of your vehicle. Safe for waxes and polymers. Does not contain any added waxes or polymers.',
    capacity: '500ml / 1L / 5L',
    ph: '7.5',
    usage: [
      'Dilute according to recommendations and your preferences. 30-50ml to 10L of water.',
      'Wash the car using clean and soft sponge, glove or mitt.',
      'Rinse thoroughly under pressure.',
    ],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_shampoo_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: exteriorBannerMobile, device: 'mobile', default: true },
      { src: exteriorBannerDesktop, device: 'tablet', default: false },
      { src: exteriorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // APC
  {
    id: 10,
    categoryId: 2,
    name: 'APC - All Purpose Cleaner',
    shortDescription: 'Highly concentrated All Purpose Cleaner for toughest jobs out there!',
    longDescription:
      'Concentrated, powerful, versatile for cleaning both interior and exterior of vehicles. Dirt on plastics, vinyl, fabric upholstery and other interior surfaces won’t stand a chance against our APC. It can also be used as a pre-cleaner on body work and rims. Does an amazing job in the engine compartment too! Removes remaining waxes and sealants from body work. Safe for cleaned surfaces.',
    capacity: '500ml / 5L',
    usage: [
      'Dilute according to recommendations and your preferences. 1: 10 - heavy dirt  |  1:10 - 1: 100 - light dirt',
      'Spray on the surface and wait until it reacts with dirt (1- 2 minutes ). Do not allow to dry on the surface.',
      'In the case of heavy dirt, spray surface and brush, then use the brush to agitate the dirt. (or sponge)',
      'Wipe the surface using cloth or rinse with water.',
      'If necessary, repeat steps 2 - 4.',
    ],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_apc_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: exteriorBannerMobile, device: 'mobile', default: true },
      { src: exteriorBannerDesktop, device: 'tablet', default: false },
      { src: exteriorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Prewash
  {
    id: 11,
    categoryId: 2,
    name: 'Prewash',
    shortDescription: 'Prewash for the toughest jobs out there!',
    longDescription:
      'PreWash is safe and effective product for removing tar, road salt, oils and other stubborn dirt. Its semi-gel consistency facilitates better surface coverage and also extends the evaporation time.',
    capacity: '1L / 5L',
    usage: [
      'Shake bottle thoroughly before use (contents might separate)',
      'Spray directly onto the surface, covering it completely.',
      'Wait for the product to start working.',
      '(Minimum of 10 minutes. If used indoors can be left to work for few hours)',
      'Rinse thoroughly with water or active foam, and then with water.',
    ],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_prewash_1L.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: exteriorBannerMobile, device: 'mobile', default: true },
      { src: exteriorBannerDesktop, device: 'tablet', default: false },
      { src: exteriorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // IPA
  {
    id: 12,
    categoryId: 2,
    name: 'IPA - 99% Isopropyl alcohol',
    shortDescription:
      'IPA - 99% Pure Isopropyl Alcohol (also known as isopropanol) for all-purpose cleaning and degreasing.',
    longDescription:
      'Removes light grease, oils, dirt etc. Evaporates quickly and leaves surface squeaky clean. Perfect choice for final preparation, cleaning and degreasing of all substrates prior to adhesive bonding,  degreasing paintwork before applying waxes or ceramic coatings. Safe for paintwork.',
    capacity: '1L / 5L',
    video: 'MnU-8jjMk8E',
    image: 'carkleans_IPA_1L.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: exteriorBannerMobile, device: 'mobile', default: true },
      { src: exteriorBannerDesktop, device: 'tablet', default: false },
      { src: exteriorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Surface Cleaner
  {
    id: 13,
    categoryId: 2,
    name: 'Surface Cleaner PRO',
    shortDescription: 'New and Improved formula.',
    longDescription:
      'Cleans and Degreases various kinds of surfaces in one single action including wax, dirt, light silicone and road grime. Designed to use before application of self-adhesive materials. Simply spray on to a microfibre towel and wipe the surface. Product will evaporate shortly. Lemongrass smell. Trigger included.',
    capacity: '1L / 5L',
    video: 'MnU-8jjMk8E',
    image: 'carkleans_surface_cleaner_1L.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: exteriorBannerMobile, device: 'mobile', default: true },
      { src: exteriorBannerDesktop, device: 'tablet', default: false },
      { src: exteriorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // INTERIOR
  // Surface Cleaner
  {
    id: 14,
    categoryId: 3,
    name: 'Interior Cleaner',
    shortDescription: 'Ready to use interior cleaner with citrus scent.',
    longDescription:
      'Easily removes dirt from plastic, vinyl, fabric upholstery and alcantara. Leaves streak-free surface. Ready to use formula with citrus scent.',
    capacity: '500ml / 1L / 5L',
    usage: [
      'Spray onto a microﬁbre towel and wipe the surface. ',
      'In the case of heavy dirt, spray surface and brush, then use brush to agitate the dirt. ',
      'Wipe the surface dry with microﬁbre towel.',
      'Use dressing on plastic elements. (Optional)',
    ],
    ph: '11',
    warnings: ['Do not apply to hot surface and in direct sunlight.'],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_interior_cleaner_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: interiorBannerMobile, device: 'mobile', default: true },
      { src: interiorBannerDesktop, device: 'tablet', default: false },
      { src: interiorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Interior Dressing
  {
    id: 15,
    categoryId: 3,
    name: 'Interior Dressing',
    shortDescription: 'New and improved formula.',
    longDescription:
      'Designed to protect and care for interior elements such as plastic, vinyl carbon and rubber. Restores natural look while protecting against fading and cracking. Water based recipe ensures no streaks or white marks. Antistatic properties will allow you to enjoy clean interior for long time.',
    capacity: '500ml / 1L / 5L',
    usage: [
      'Use as-is or dilute 1:1 to achieve a matt finish.',
      'Use an applicator to spread the product over the plastic surface in circular motions.',
      'Using microfiber towel, remove any excess and allow to dry.',
    ],
    ph: '11',
    warnings: [
      'Do not apply in direct sunlight or on hot surfaces.',
      'Do not apply to fabric or leather. ',
    ],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_interior_dressing_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: interiorBannerMobile, device: 'mobile', default: true },
      { src: interiorBannerDesktop, device: 'tablet', default: false },
      { src: interiorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Glass Cleaner
  {
    id: 16,
    categoryId: 3,
    name: 'Glass Cleaner',
    shortDescription: 'Glass Cleaner –  Ideal for cleaning car & home windows.',
    longDescription:
      'Ready to use window and mirror cleaner. Removes dirt and grime with ease. Leaves glass perfectly streak and stain free. Comes with trigger.',
    capacity: '500ml / 1L / 5L',
    usage: [
      'Spray on the surface',
      'Wipe clean using microfiber cloth',
      'Use dry side of the cloth to buff the surface',
      'If needed, repeat the process',
    ],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_glass_cleaner_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: interiorBannerMobile, device: 'mobile', default: true },
      { src: interiorBannerDesktop, device: 'tablet', default: false },
      { src: interiorBannerLaptop, device: 'laptop', default: false },
    ],
  },

  // Leather QD
  {
    id: 17,
    categoryId: 3,
    name: 'Leather QD',
    shortDescription: 'Leather Care',
    longDescription:
      'Leather QD is designed for regular leather care. Advanced cleaning formula removes those fresh stains. Oils contained in the bottle provide effective leather protection (against cracking and harmful UV rays). Moisturizes the leather. Safe for all leather types and colours. Pleasant, slightly perfumed scent.',
    capacity: '500ml',
    usage: [
      'Shake before use.',
      'Spray onto a microfibre towel and clean the leather.',
      'Dry with the other side of towel.',
    ],
    warnings: [
      'H318 Causes serious eye damage.',
      'H412 Harmful to aquatic life with long lasting effects.',
      'P273 Avoid release to the environment.',
      'P280: Wear protective gloves/protective clothing/eye protection/face protection.',
      'P305+351+338: IF IN EYES: Rinse cautiously with water for several minutes. Remove contact lenses, if present and easy to do. Continue rinsing.',
      'P310 Immediately call a POISON CENTER or doctor/physician.',
      'P501: Dispose of contents/container in accordance with regulations on hazardous waste or packaging and packaging waste respectively',
    ],
    video: 'MnU-8jjMk8E',
    image: 'carkleans_leatherQD_500ml.png',
    gallery: [
      'product_1_gallery_1.png',
      'product_1_gallery_2.png',
      'product_1_gallery_3.png',
      'product_1_gallery_4.png',
    ],
    bannerImages: [
      { src: interiorBannerMobile, device: 'mobile', default: true },
      { src: interiorBannerDesktop, device: 'tablet', default: false },
      { src: interiorBannerLaptop, device: 'laptop', default: false },
    ],
  },
]

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
})

export default productsSlice.reducer
