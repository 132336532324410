import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../store'

import device from '../../styles/devices'
import { Category } from '../../types/Category'
import { randomString } from '../../utils'
import ProductTile from './ProductTile'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;

  padding-top: 72px;
  padding-bottom: 72px;

  @media ${device.tablet} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding-bottom: 160px;
  }
`

type Props = {
  category: Category
}

function ProductTiles({ category }: Props) {
  function useRenderProducts() {
    const products = useSelector((state: RootState) =>
      state.products.filter((p) => p.categoryId === category.id)
    )

    return products.map((product) => {
      return <ProductTile category={category} product={product} key={randomString()} />
    })
  }

  return (
    <section className="bg-gray pt-20">
      <Wrapper className="container">{useRenderProducts()}</Wrapper>
    </section>
  )
}

export default ProductTiles
