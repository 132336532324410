import { FormEvent, useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'
import styled from 'styled-components'
import Dialog from '../../dialog'
import device from '../../styles/devices'
import { emailValid, isEmpty } from '../../validators'
import ButtonPrimary from '../general/ButtonPrimary'
import FormInput from '../general/form/FormInput'
import FormTextarea from '../general/form/FormTextarea'

import './contact-form.scss'

const Wrapper = styled.section`
  margin-bottom: 48px;
`

const Heading = styled.h1`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 32px;
  text-transform: uppercase;
`
const Form = styled.form``

const TopBox = styled.div`
  @media ${device.laptop} {
    display: flex;
    column-gap: 64px;

    & > * {
      flex: 1 0 auto;
    }
  }
`

const ButtonContainer = styled.div`
  @media ${device.laptop} {
    display: flex;
    justify-content: flex-end;
  }
`

const StyledButton = styled(ButtonPrimary)`
  width: 100%;

  @media ${device.laptop} {
    width: auto;
    padding: 0 32px;
  }
`
const HoneypotInput = styled(FormInput)`
  position: absolute;
  top: -2000px;
  left: -2000px;
`

type FormData = {
  name: string
  surname: string
  email: string
  phone: string
  subject: string
  message: string
}

function ContactForm() {
  const dialog = new Dialog()

  const formRef = useRef<HTMLFormElement>(null)

  const initialFormData: FormData = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  }

  const [formData, setFormData] = useState<FormData>(initialFormData)

  const initialErrors: FormData = useMemo(() => {
    return {
      name: '',
      surname: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    }
  }, [])

  const [errors, setErrors] = useState<FormData>(initialErrors)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  function scrollToTopmostError() {
    const form = formRef.current

    if (form) {
      window.setTimeout(() => {
        const firstError = form.querySelector('.form-error')
        const bodyRect = document.body.getBoundingClientRect()
        const elemRect = firstError?.getBoundingClientRect()
        if (elemRect) {
          const offset = elemRect.top - bodyRect.top
          window.scrollTo({
            top: offset - window.screen.height / 4,
            behavior: 'smooth',
          })
        }
      }, 10)
    }
  }

  async function doSubmit() {
    const res = await fetch('https://carkleans.com/v1/sendform', {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    if (res.status === 200) {
      dialog.show('Message Sent!', 'We will get back to you soon!')
      setFormData(initialFormData)
    } else
      dialog.show(
        'Sorry!',
        `Seems like we are experiencing some issues. Please try again later or e-mail us directly at sales@carkleans.com`
      )

    setIsLoading(false)
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (isLoading) return

    setIsLoading(true)

    const nameValid = !isEmpty(formData.name)
    const eValid = emailValid(formData.email).valid
    const messsageValid = !isEmpty(formData.message)

    setErrors({
      name: nameValid ? '' : 'Please enter your name!',
      surname: '',
      email: eValid ? '' : 'Please enter a valid e-mail address!',
      phone: '',
      subject: '',
      message: messsageValid ? '' : 'Please enter a message!',
    })
  }

  useEffect(() => {
    if (errors !== initialErrors) {
      let key: keyof typeof errors
      for (key in errors) {
        if (errors[key]) {
          scrollToTopmostError()
          setIsLoading(false)
          return
        }
      }

      doSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors, errors])

  function getButtonContent() {
    if (isLoading) return <AiOutlineLoading className="animate-rotate" />
    else return <span>SEND</span>
  }

  return (
    <Wrapper className="container">
      <Heading>Send Us an Email</Heading>

      <Form ref={formRef} onSubmit={onSubmit} noValidate>
        <TopBox>
          <FormInput
            id="contact-name"
            label="Name"
            type="text"
            error={errors.name}
            value={formData.name}
            tabindex={1}
            autofocus
            onChange={(name) => {
              setFormData({ ...formData, name })
            }}
          />

	 <HoneypotInput
		id="contact-surname"
		label="Surname"
		type="text"
		error={errors.surname}
		value={formData.surname}
		tabindex={-1}
		onChange={(surname)=> {
		  setFormData({ ...formData, surname})
		}}
	  />

          <FormInput
            id="contact-email"
            label="Email"
            type="email"
            error={errors.email}
            value={formData.email}
            tabindex={2}
            onChange={(email) => {
              setFormData({ ...formData, email })
            }}
          />

	  <HoneypotInput
		id="contact-phone"
		label="Phone"
		type="text"
		error={errors.phone}
		value={formData.phone}
		tabindex={-1}
		onChange={(phone) => {
		  setFormData({ ...formData, phone })
		}}
	  />

	 <HoneypotInput
		id="contact-subject"
		label="Subject"
		type="text"
		error={errors.subject}
		value={formData.subject}
		tabindex={-1}
		onChange={(subject)=> {
		  setFormData({ ...formData, subject})
		}}
	  />
	
        </TopBox>

        <FormTextarea
          id="contact-message"
          label="Your Message"
          value={formData.message}
          error={errors.message}
          tabindex={3}
          onChange={(message) => {
            setFormData({ ...formData, message })
          }}
        />

        <ButtonContainer>
          <StyledButton type="submit">{getButtonContent()}</StyledButton>
        </ButtonContainer>
      </Form>
    </Wrapper>
  )
}

export default ContactForm
