import styled from 'styled-components'
import device from '../../../styles/devices'

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  position: relative;
  max-width: 45%;
  padding: 15px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:before,
  &:after {
    content: '';
    background-color: var(--color-primary);
    position: absolute;
    height: 6px;
  }

  &:before {
    top: 0;
    left: 0;
    width: 66%;
  }

  &:after {
    bottom: 0;
    right: 0;
    width: 34%;
  }

  h1 {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 10px;
  }

  @media ${device.tablet} {
    h1 {
      font-size: 35px;
    }

    p {
      font-size: 20px;
    }
  }

  @media ${device.laptopLarge} {
    max-width: 30%;

    h1 {
      font-size: 40px;
    }

    p {
      font-size: 30px;
      font-weight: 300;
    }
  }
`

type Props = {
  children: React.ReactNode
  className?: string
}

function MessageBox(props: Props) {
  return <Wrapper className={props.className}>{props.children}</Wrapper>
}

export default MessageBox
