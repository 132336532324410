import { ReactElement } from 'react'
import styled from 'styled-components'

const Btn = styled.a`
  display: inline-block;
  box-sizing: border-box;
  border: none;
  background-color: var(--color-primary);
  color: #000;
  height: 36px;
  font-size: 18px;
  font-weight: 550;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &:hover {
    transform: translateY(-0.25em);
    box-shadow: 0 0.5em 0.5em -0.4em var(--color-primary);
  }

  &.uppercase {
    text-transform: uppercase;
  }
`

type Props = {
  href: string
  children: string | ReactElement | ReactElement[]
  className?: string
}

function LinkPrimary({ href, children, className }: Props) {
  return (
    <Btn href={href} className={className}>
      {children}
    </Btn>
  )
}

export default LinkPrimary
