import { Loader, LoaderOptions } from 'google-maps'
import styled from 'styled-components'
import device from '../../../styles/devices'

// import carkleansLogo from '../../../assets/carkleans-logo.svg'
import edgeDetailingLogo from '../../../assets/edge-detailing-logo.png'
import raproCareLogo from '../../../assets/rapro-logo.avif'
import customPin from '../../../assets/custom-pin.png'
import { MAPS_API_KEY } from '../../../config'

const ComponentWrapper = styled.section`
  position: relative;
  z-index: 0;

  h2 {
    position: absolute;
    z-index: 50;
    background-color: rgba(255, 255, 255, 0.9);
    top: 15px;
    left: 15px;
    right: 15px;
    text-align: center;
    padding: 6px 0;
    text-transform: uppercase;

    @media ${device.tablet} {
      left: auto;
      padding: 12px;
    }

    @media ${device.laptopLarge} {
      padding: 12px 24px;
      font-size: 30px;
    }
  }
`

const OffsetWrapper = styled.div`
  @media ${device.tablet} {
    position: relative;
    top: -100px;
    margin-bottom: -115px;
  }
`

const MapWrapper = styled.div`
  height: 100vw;
  width: 100%;

  @media ${device.tablet} {
    height: 80vw;
  }

  @media ${device.laptopLarge} {
    height: 50vw;
  }

  @media ${device.desktop} {
    height: 800px;
  }
`

const options: LoaderOptions = {}
const loader = new Loader(MAPS_API_KEY, options)

type Props = {
  className?: string
}

function HomepageMap({ className }: Props) {
  async function init() {
    const google = await loader.load()

    const root = document.querySelector('#map')
    const pins = [
      {
        title: 'Edge Detailing',
        coordinates: { lat: 51.87586420510113, lng: -8.481810117053591 },
        content: `
        <div style="background:black;padding-top:20px;padding-bottom:20px;">
        <img src="${edgeDetailingLogo}" style="margin: 0 auto;max-width: 80%;" />
        <div style="padding: 20px;color:#fff;">
          <h1 style="margin-bottom: 20px;">EDGEDETAILING</h1>
          <p>Unit 2, John Harrington Industrial Estate</p>
          <p>Tramore Rd</p>
          <p>Ballyphehane</p>
          <p>Cork, T12 W5RW</p>
          <a style="display:block;margin-top:20px;" href="https://shop.edgedetailing.ie">https://shop.edgedetailing.ie</a>
          <a style="display:block;margin-top:20px;" href="tel:+353851104507">(+353) 85 110 4507</a>
        </p>
        </div>
      </div>`
      },
      {
        title: 'Rapro Care',
        coordinates: { lat: 51.900680439417975, lng: -8.47599501498544 },
        content: `
        <div style="background:black;padding-top:20px;padding-bottom:20px;">
        <img src="${raproCareLogo}" style="margin: 0 auto;max-width: 80%;" />
        <div style="padding: 20px;color:#fff;">
          <h1 style="margin-bottom: 20px;">RAPRO CARE</h1>
          <p>39 Lavitts Quay</p>
          <p>Cork City, T12 P621</p>
          <a style="display:block;margin-top:20px;" href="https://rapro.ie/">https://rapro.ie/</a>
          <a style="display:block;margin-top:20px;" href="tel:+353214814371">(021) 481 4371</a>
        </p>
        </div>
      </div>`
      }
    ]

    if (root) {
      const map = new google.maps.Map(root, {
        center: { lat: 51.890626, lng: -8.478329 },
        zoom: 13,
        disableDefaultUI: true,
      })

      for (const pin of pins) {

        const infoWindow = new google.maps.InfoWindow({ content: pin.content })

        const marker = new google.maps.Marker({
          position: pin.coordinates,
          map,
          title: pin.title,
          icon: customPin,
        })

        marker.addListener('click', () => {
          const iw = infoWindow as any
          iw.open({
            anchor: marker,
            map,
            shouldFocus: false,
          })
        })
      }
    }
  }

  init()

  return (
    <ComponentWrapper className={className}>
      <OffsetWrapper>
        <h2>
          <span>
            Find a Merchant Near You
            <br />
            and Place Your Order Today
          </span>
        </h2>
        <MapWrapper id="map" />
      </OffsetWrapper>
    </ComponentWrapper>
  )
}

export default HomepageMap
