import PageBanner from '../../components/general/page-banner/PageBanner'
import Statement from '../../components/general/Statement'
import WhyUs from '../../components/about/WhyUs'
import MoreInfo from '../../components/about/MoreInfo'
import Footer from '../../components/general/Footer'
import ImageSource from '../../types/ImageSource'

import { RiArrowRightSLine } from 'react-icons/ri'

import aboutBanner_square from '../../assets/about/banner/about_1600_1600.jpg'
import aboutBanner_1024_384 from '../../assets/about/banner/about_1024_384.jpg'
import bannerLaptop from '../../assets/about/banner/about-laptop.jpg'

import iconCaret from '../../assets/caret.svg'

import iconLogo from '../../assets/about/icons/logo.svg'
import iconMap from '../../assets/about/icons/map.svg'
import iconCar from '../../assets/about/icons/car.svg'
import iconHand from '../../assets/about/icons/hand.svg'

import styled from 'styled-components'
import device from '../../styles/devices'

const Section = styled.section`
  padding-top: 50px;
  padding-bottom: 50px;

  .image-container {
    display: flex;
    justify-content: center;

    img {
      max-width: 60%;
    }
  }

  h2 {
    margin-top: 24px;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }

  p {
    font-size: 18px;
  }

  .products-list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    position: relative;

    .left,
    .right {
      p {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .caret {
          fill: var(--color-primary);
          width: 20px;
          height: auto;
        }
      }
    }
  }

  & + & {
    border-top: 2px solid #000;
  }

  @media ${device.tablet} {
    display: flex;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    .image-container {
      min-width: 200px;
      img {
        max-width: none;
      }
    }

    .content-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 40px;
      padding-right: 40px;

      h2 {
        text-align: left;
        font-size: 18px;
        margin: 0;
      }

      p {
        font-size: 14px;
      }

      .products-list {
        flex-direction: row;
      }
    }
  }

  @media ${device.laptopLarge} {
    .content-wrap {
      h2 {
        font-size: 32px;
      }

      p {
        font-size: 18px;
      }
    }
  }
`

function About() {
  const bannerImages: ImageSource[] = [
    { src: aboutBanner_square, device: 'mobile', default: true },
    { src: aboutBanner_1024_384, device: 'tablet', default: false },
    { src: bannerLaptop, device: 'laptop', default: false },
    { src: bannerLaptop, device: 'laptopLarge', default: false },
  ]

  return (
    <main>
      <PageBanner sources={bannerImages} alt="About page banner" heading="GET TO KNOW US" />

      <Statement className="mt-10 mb-10 md-mt-20 md-mb-20">
        <div>
          <Section>
            <div className="image-container">
              <img src={iconLogo} alt="carkleans logo icon" />
            </div>
            <div className="content-wrap">
              <h2>CarKleans: The Story Behind The Shine</h2>
              <p className="mt-5">
                The idea behind CarKleans is simple: We sell professional car cleaning products and
                accessories that create positive emotions; like hapiness, joy, fulfillness. Those
                are just some of the feelings you will experience once you clean your car using
                CarKleans. Every car enthusiast love their car(s); so do we, and we love when our
                cars sparkle. The problem was that there wasn’t a safe, easy-to-use, and affordable
                car cleaning product on the market that produced exceptional results. That’s why we
                decided to create CarKleans.
              </p>
            </div>
          </Section>

          <Section>
            <div className="image-container">
              <img src={iconMap} alt="map icon" />
            </div>
            <div className="content-wrap">
              <h2>From Our Home To Yours</h2>
              <p className="mt-5">
                We bottle every car cleaning product in the CarKleans lineup ourselves in our
                hometown of Cork in Ireland. It’s not just about selling a product to us; we want to
                help people across the globe to feel great and make a great impression anywhere they
                travel.
              </p>
            </div>
          </Section>

          <Section>
            <div className="image-container">
              <img src={iconCar} alt="car icon" />
            </div>
            <div className="content-wrap">
              <h2>It’s Your Moment To Shine</h2>
              <p className="mt-5">
                With CarKleans, we put the power into your hands. No one knows how to take care of
                your pride and joy better than yourself. Thats why we offer a full selection of the
                best car cleaning products around:
              </p>
              <div className="products-list">
                <div className="left">
                  <p>
                    <RiArrowRightSLine className="caret" />
                    Wheel and Tire Cleaners
                  </p>
                  <p>
                    <RiArrowRightSLine className="caret" />
                    Bug Removers
                  </p>
                  <p>
                    <RiArrowRightSLine className="caret" />
                    Interior Cleaners &amp; Dressings
                  </p>
                  <p>
                    <RiArrowRightSLine className="caret" />
                    Shampoos
                  </p>
                </div>
                <div className="right">
                  <p>
                    <RiArrowRightSLine className="caret" />
                    Prewash &amp; APC
                  </p>
                  <p>
                    <RiArrowRightSLine className="caret" />
                    Surface and Glass Cleaners
                  </p>
                  <p>
                    <RiArrowRightSLine className="caret" />
                    Leather Cleaners &amp; Protectants
                  </p>
                  <p>
                    <RiArrowRightSLine className="caret" />
                    And Many More!
                  </p>
                </div>
              </div>
            </div>
          </Section>

          <Section>
            <div className="image-container">
              <img src={iconHand} alt="ok hand icon" />
            </div>
            <div className="content-wrap">
              <h2>Easy, Fast, and Fun</h2>
              <p className="mt-5">
                Keeping your car looking its best doesn’t have to be a chore. With the high-quality
                car cleaning products from CarKleans, you’ll love how easy it is to care for your
                vehicle. Be careful; once you hit the road in a clean car by CarKleans, you won’t
                ever want to ride dirty again. You’re going to love the way your car looks and
                feels. We guarantee it!
              </p>
            </div>
          </Section>
        </div>
      </Statement>

      <WhyUs className="pb-10" />

      <MoreInfo />

      <Footer />
    </main>
  )
}

export default About
