import { Route, Routes } from 'react-router-dom'

import 'resetcss'
import './App.scss'

import Header from './components/general/Header'

import Homepage from './pages/homepage/Homepage'
import About from './pages/about/About'

import WheelsTires from './pages/categories/WheelsTires'
import Exterior from './pages/categories/Exterior'
import Interior from './pages/categories/Interior'
import Accessories from './pages/categories/Accessories'
import Product from './pages/product/Product'
import Merchants from './pages/merchants/Merchants'
import Contact from './pages/contact/Contact'
import Downloadables from './pages/downloadables/Downloadables'

function App() {
  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/categories/wheels-tires" element={<WheelsTires />} />
        <Route path="/categories/exterior" element={<Exterior />} />
        <Route path="/categories/interior" element={<Interior />} />
        <Route path="/categories/accessories" element={<Accessories />} />
        <Route path="/products/:id" element={<Product />} />
        <Route path="/merchants" element={<Merchants />} />
        <Route path="/downloadables" element={<Downloadables />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  )
}

export default App
