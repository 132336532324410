export const DEVICE_WIDTH = {
  mobile: 767,
  tablet: 768,
  laptop: 1024,
  laptopLarge: 1280,
  desktop: 1536,
}

const SIZE = {
  mobile: `${DEVICE_WIDTH.mobile}px`,
  tablet: `${DEVICE_WIDTH.tablet}px`,
  laptop: `${DEVICE_WIDTH.laptop}px`,
  laptopLarge: `${DEVICE_WIDTH.laptopLarge}px`,
  desktop: `${DEVICE_WIDTH.desktop}px`,
}

const device = {
  mobile: `(max-width: ${SIZE.mobile})`,
  tablet: `(min-width: ${SIZE.tablet})`,
  laptop: `(min-width: ${SIZE.laptop})`,
  laptopLarge: `(min-width: ${SIZE.laptopLarge})`,
  desktop: `(min-width: ${SIZE.desktop})`,
}

export default device

export function getCurrentDevice() {
  let device = 'mobile'
  for (const [key, value] of Object.entries(DEVICE_WIDTH)) {
    if (value <= window.innerWidth) {
      device = key
    } else {
      return device
    }
  }
}
