import { NavLink } from 'react-router-dom'

type Props = {
  text: string
  to: string
  onClick?: () => void
}

function NavItem({ text, to, onClick }: Props) {
  return (
    <li>
      <NavLink to={to} onClick={onClick}>
        <span>{text}</span>
      </NavLink>
    </li>
  )
}

export default NavItem
