import './form-controls.scss'

type Props = {
  className?: string;

  id: string
  label: string
  type: 'text' | 'password' | 'email'

  value: string
  tabindex?: number
  autofocus?: boolean
  onChange: (value: string) => void

  error?: string
}

function FormInput({ className, id, label, type, value, tabindex, autofocus, onChange, error }: Props) {
  return (
    <div className={`form-control ${error && 'has-error'} ${className ?? ''}`}>
      <label htmlFor={id}>{label}</label>
      <input id={id} type={type} value={value} tabIndex={tabindex} autoFocus={autofocus} onChange={(e) => onChange(e.target.value)} />
      <p className="form-error">{error}</p>
    </div>
  )
}

export default FormInput
