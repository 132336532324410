import { ReactElement } from 'react'
import styled from 'styled-components'

const Btn = styled.button`
  box-sizing: border-box;
  border: none;
  background-color: var(--color-primary);
  color: #000;
  height: 36px;
  font-size: 18px;
  font-weight: 550;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &.uppercase {
    text-transform: uppercase;
  }

  &:enabled {
    &:hover {
      transform: translateY(-0.25em);
      box-shadow: 0 0.5em 0.5em -0.4em var(--color-primary);
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`

type Props = {
  children: string | ReactElement | ReactElement[]
  className?: string
  type?: 'button' | 'submit'
  disabled?: boolean
}

function ButtonPrimary({ children, className, disabled = false, type = 'button' }: Props) {
  return (
    <Btn className={className} disabled={disabled} type={type}>
      {children}
    </Btn>
  )
}

export default ButtonPrimary
