import { ReactElement, useLayoutEffect, useRef } from 'react'

import './carousel_vertical.scss'

import caret from '../../../assets/caret.svg'

type Props = {
  children: ReactElement | ReactElement[]
}

function CarouselVertical({ children }: Props) {
  const GAP = 20

  const containerRef = useRef<HTMLDivElement>(null)
  const previousRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)

  let slides = useRef([])

  // Getters
  async function getContainer(delay = 0): Promise<HTMLDivElement> {
    return new Promise((resolve, reject) => {
      const container = containerRef.current
      if (container) resolve(container)
      else {
        window.setTimeout(() => {
          getContainer(10)
        }, delay)
      }
    })
  }

  async function getCurrentSlide() {
    const container = await getContainer()
    return container.querySelector('.carousel-slide[data-active=true]') as HTMLElement
  }

  // Render Functions
  function renderSlides() {
    return children
  }

  // Init Functions
  let isInitialised = false
  function init() {
    if (isInitialised) return
    isInitialised = true

    initSlides()
    initButtons()

    setCarouselHeight(0)
  }

  async function initSlides() {
    const container = await getContainer()

    slides.current = Array.from(container.querySelectorAll('.carousel-slide'))

    for (let i = 0; i < slides.current.length; i++) {
      const slide = slides.current[i] as HTMLElement

      if (i === 0) slide.setAttribute('data-active', 'true')

      slide.setAttribute('data-index', i.toString())

      slide.style.transform = `translateY(calc(${i * 100}% + ${GAP * i}px))`
    }
  }

  function initButtons() {
    const btnNext = nextRef.current
    const btnPrev = previousRef.current

    if (btnNext && btnPrev) {
      btnNext.removeEventListener('click', onNext)
      btnPrev.removeEventListener('click', onPrevious)

      btnNext.addEventListener('click', onNext)
      btnPrev.addEventListener('click', onPrevious)
    }
  }

  function setCarouselHeight(delay: number, onInit: Function | null = null) {
    window.setTimeout(async () => {
      const container = await getContainer()

      let height = 0
      const child = container.firstChild as HTMLElement
      const rect = child.getBoundingClientRect()

      height = rect.height * 2

      if (height === 0) {
        setCarouselHeight(10, onInit)
      } else {
        container.style.height = `${height}px`
        console.log(height)

        if (typeof onInit === 'function') {
          onInit()
        }
      }
    }, delay)
  }

  // Navigation Events
  async function onNext() {
    const currentSlide = await getCurrentSlide()
    const currentIndex = Number(currentSlide.getAttribute('data-index'))

    if (currentIndex === slides.current.length - 2) {
      setActiveSlide(0)
    } else {
      setActiveSlide(currentIndex + 1)
    }
  }

  async function onPrevious() {
    const currentSlide = await getCurrentSlide()
    const currentIndex = Number(currentSlide.getAttribute('data-index'))

    if (currentIndex === 0) {
      setActiveSlide(slides.current.length - 2)
    } else {
      setActiveSlide(currentIndex - 1)
    }
  }

  // Navigation Functions
  async function setActiveSlide(index: number) {
    const currentSlide = await getCurrentSlide()
    const container = await getContainer()

    currentSlide.removeAttribute('data-active')

    const nextSlide = container.querySelector(`.carousel-slide[data-index="${index}"]`)
    nextSlide?.setAttribute('data-active', 'true')

    moveSlide(index)
  }

  function moveSlide(index: number) {
    for (let i = 0; i < slides.current.length; i++) {
      const slide = slides.current[i] as HTMLElement

      const percentage = 100 * (i - index)
      const gap = GAP * (i - index)
      const value = `calc(${percentage}% + ${gap}px)`
      slide.style.transform = `translateY(${value})`

      //   slide.style.transform = `translateY(${100 * (i - index)}%)`
    }
  }

  useLayoutEffect(() => {
    init()
  }, [])

  window.addEventListener('resize', () => {
    setCarouselHeight(0)
  })

  return (
    <div className="carousel-wrapper vertical">
      <button ref={previousRef} className="carousel-nav-button button-previous">
        <img src={caret} alt="Previous Slide" />
      </button>
      <div ref={containerRef} className="carousel-container">
        {renderSlides()}
      </div>
      <button ref={nextRef} className="carousel-nav-button button-next">
        <img src={caret} alt="Next Slide" />
      </button>
    </div>
  )
}

export default CarouselVertical
