import Picture from '../../general/Picture'

import { DEVICE_WIDTH } from '../../../styles/devices'

import homepageImageS from '../../../assets/homepage/homepage-product-group-shot-s.png'
import homepageImageL from '../../../assets/homepage/homepage-product-group-shot-l.png'
import homepageImageXL from '../../../assets/homepage/homepage-product-group-shot-xl.png'

import ImageSource from '../../../types/ImageSource'

import './homepage-mission.scss'
import styled from 'styled-components'

const Wrapper = styled.section`
  @media (min-width: 1750px) {
  }

  @media (min-width: 1850px) {
  }
`

const Heading = styled.h1`
  font-size: 28px;
`

const Subheading = styled.h2`
  font-size: 24px;
  margin: 20px 0;
`

function HomepageMission() {
  const imageSources: ImageSource[] = [
    { src: homepageImageS, device: null, breakpoint: { min: '0px', max: '549px' }, default: true },
    {
      src: homepageImageL,
      device: null,
      breakpoint: { min: '550px', max: `${DEVICE_WIDTH.tablet}px` },
      default: false,
    },
    { src: homepageImageL, device: 'tablet', default: false },
    { src: homepageImageXL, device: 'laptop', default: false },
  ]

  return (
    <Wrapper>
      <div className="homepage--mission-flex-wrapper container mt-5 mb-5">
        <div className="homepage--mission-text-wrapper">
          <div className="p-box">
            <Heading>CarKleans: A Clean Car Is a Happy Car</Heading>
            <Subheading>Professional Car Cleaning Products For Everyone</Subheading>
            <p>
              Nothing beats the feeling of achieving success on your own, especially when it turns
              out perfectly. That’s the feeling that CarKleans wants you to have when you give your
              vehicle a professional finish at home. With our full selection of professional car
              cleaning products and accessories, you won’t just be washing your car; you’ll make it
              shine like a star.
            </p>
            <Subheading>Explore Our Lineup of Professional Products Today</Subheading>
          </div>
        </div>
        <div className="homepage--mission-image-wrapper">
          <Picture sources={imageSources} alt="CarKleans Citrus Foam" />
        </div>
      </div>
    </Wrapper>
  )
}

export default HomepageMission
